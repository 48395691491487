.AppFooter {
  background-color: #1e2133;
  bottom: 0;
  max-width: 1440px;
  height: 107px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 110px 36px 225px;
}

.appFooterLogo {
  width: 46px;
  height: 46px;
  margin: 3px 0 0 -107px;
  vertical-align: middle
}

.appFooterText {
  display: inline-block;
  white-space: nowrap;
  margin: 14px 200px 13px 34px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #ffffff;
  font-family: museo-sans;
}

.appFooterVersion {
  display: inline-block;
  white-space: nowrap;
  margin: 14px 14px 13px 34px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #ffffff;
  font-family: museo-sans;
  width: 100%;
  text-align: right;
}

@media only screen and (max-width: 1024px) {
  .AppFooter {
    padding: 20px;
  }

  .appFooterVersion,
  .appFooterLogo {
    margin: 0;
  }

  .appFooterText {
    margin: 0 0 0 24px;
    font-size: 12px;
    line-height: 19px;
  }
}