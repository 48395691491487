.rmd-button-welcome-email-invites {
    border: 1px solid #1e2133;
    border-radius: 3px;
}

#welcomeEmail-chkbox-0 {
    color: #fff;
}

.MuiBox-root {
    padding-left: 0 !important;
    margin: 0;
}

.MuiBox-root>.MuiFormControl-root {
    margin-right: 0 !important;
    width: 100%;
}

.cancelButtonInActive1 {
    width: 116px;
    height: 35px;
    border-radius: 0px;
    border: solid 2px #2d3b59;
    background-color: #fff;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: #2d3b59;
    font-size: 15px;
    margin: 35px 5px 45px 5px;
    border: 2px solid #2e334e;
    Padding: 9px, 20px, 9px, 20px;
}

.disabledCancelButtonInActive1 {
    width: 116px;
    height: 35px;
    border-radius: 0px;
    border: solid 2px #979797;
    background-color: #fff;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: #979797; /* Updated color */
    font-size: 15px;
    margin: 35px 5px 45px 5px;
    padding: 9px 20px; /* Corrected padding syntax */
    opacity: 0.75; /* Added opacity */
}

.emailInvites-activate {
    width: 116px;
    height: 35px;
    background-color: #2e334e;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: white;
    font-size: 16px;
    margin: 0 5px 0 5px;
    border: 2px solid #2e334e;
    Padding: 9px, 20px, 9px, 20px;
    border-radius: 0;
}

.disabledEmailInvites-activate {
    width: 116px;
    height: 35px;
    background-color: #979797;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: #ffffff; /* Updated color */
    font-size: 16px;
    margin: 0 5px 0 5px;
    border: 2px solid #979797;
    padding: 9px 20px; /* Corrected padding syntax */
    border-radius: 0;
    opacity: 0.75; /* Added opacity */
}

.emailInvites-disable-activate {
    width: 116px;
    height: 35px;
    background-color: #979797;
    font-family: museo-sans;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    font-style: normal;
    color: white;
    font-size: 16px;
    margin: 0 5px 0 5px;
    Padding: 9px, 20px, 9px, 20px;
    border: 2px solid #2e334e;
    border-radius: 0;
}

.emailInvites-deactivate {
    width: 98px;
    height: 35px;
    background-color: white;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 19.2px;
    letter-spacing: normal;
    color: #2e334e;
    font-size: 16px;
    margin: 0 5px 0 5px;
    border: 2px solid #2e334e;
    Padding: 9px, 20px, 9px, 20px;
    border-radius: 0;
}

.emailInvites-disable-deactivate {
    width: 98px;
    height: 35px;
    background-color: white;
    font-family: museo-sans;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 19.2px;
    letter-spacing: normal;
    color: #979797;
    font-size: 15px;
    margin: 0 5px 0 5px;
    border: 2px solid #2e334e;
    Padding: 9px, 20px, 9px, 20px;
    border-radius: 0;
} 

.welcome-email-actdeact-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.subtitle-text {
    font-family: Georgia;
    font-weight: bold;
    font-size: 21px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-columnHeaders {
    background-color: #f7f7f7 !important;
}

#welcome-email-actdeact-dialog .MuiDataGrid-columnHeaderTitle {
    color: #2e334e;
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 700;
}


#welcome-email-actdeact-dialog .MuiDataGrid-columnHeadersInner {
    margin: 0 27px 0 27px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-virtualScroller {
    margin: 0 27px 0 27px;
    border-bottom: 0px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-root {
    border-bottom: none;
}

.welcome-email-deact-dialog .MuiDataGrid-row {
    font-family: 'museo-sans';
    color: #4e2e40;
    font-weight: 700;
}

.welcome-email-act-dialog .MuiDataGrid-row {
    font-family: 'museo-sans';
    color: 4b4b4b;
    font-weight: 500;
}