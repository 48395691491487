.closeIcon {
  width: 14px;
  height: 14px;

  &:before, &:after {
    position: absolute;
    left: 50%;
    content: " ";
    height: 100%;
    width: 1px;
    background-color: #2E334E;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}