@media screen and (min-width: 1025px) {
    #Refund\ \/\ Payment\ Preferences {
        .sectionText,
        .sectionText.pt,
        .sectionHeaderLabel.mt,
        .sectionHeaderLabel {
            text-align: left;
            padding: 0 0 16px 17px;
            align-items: center;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-family: museo-sans;
            font-size: 13px;
            color: #4b4b4b;
        }

        .rmd-grid.formLineRowContainer {
            margin: 0 26px;
            height: auto;
            padding: 16px;
        }

        .rmd-grid.formLineRowContainer.firstGroup {
            >*:nth-last-child(4),
            >*:nth-last-child(7) {
                display: none;
            }
        }

        .mobileLineItem.fieldsContainer {
            grid-row-gap: 0;
            margin: 0 26px;
            height: auto;
            padding: 16px;

            .lineItemRow {
                grid-row-gap: 0;
                padding: 0;

                >*:nth-last-child(2),
                >*:nth-last-child(3) {
                    display: none;
                }

                .lineRowField:has(.summaryLabelHeader) {
                    padding-top: 10px;

                    .summaryLabelHeader,
                    .summaryDivider {
                        display: none;
                    }
                }
            }

            .lineItemRow:has(.summaryLabelHeader) {

                .lineItemRowDivider {
                    margin: 20px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #Refund\ \/\ Payment\ Preferences {
        .sectionHeaderLabel,
        .sectionHeaderLabel.mt {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: start;
            margin-top: 20px;
        }

        .sectionText,
        .sectionText.pt {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            padding: 20px 0;
        }

        .rmd-grid.formLineRowContainer {
            padding: 0 20px;
            grid-row-gap: 0;
        }

        .rmd-grid.formLineRowContainer.firstGroup {
            grid-row-gap: 0;
            padding: 0 20px;

            >.rmd-grid__cell--5 {
                padding-top: 16px;
            }
        }

        .rmd-collapse {
            margin-bottom: 4px !important;

            >*:last-child {
                padding: 20px 20px 0;

                .rmd-grid__cell--7 {
                    margin-bottom: -15px;
                }
            }
        }

        .rmd-collapse:has(.summaryLabelHeader) {
            >*:last-child {
                padding: 20px;
                align-items: flex-start;

                >.rmd-grid__cell--7 {
                    padding-bottom: 16px;
                    margin-bottom: 0;

                    .labelText {
                        padding: 0;
                    }
                }

                .rmd-grid__cell--12 {
                    grid-column-start: 1;
                }
            }

            >*:nth-last-child(2) {
                padding: 0 20px;

                >.rmd-grid__cell--7 {
                    align-self: end;

                    .labelText{
                        padding: 0;
                    }
                }

                .rmd-grid__cell--5 {
                    grid-column-start: 8;
                }

                .rmd-grid__cell--12 {
                    grid-column-start: 1;
                }
            }
        }

        .rmd-grid__cell--8 {
            grid-column: span 8;
        }

        .multiSelectText1 {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            text-align: left;
            margin: 0;
        }

        .formCheckboxesHeaderContainer {
            margin: 0;
            padding: 15px 0 16px 18px;
        }

        .rmd-form-message.lastMessage {
            margin-bottom: 20px;
        }

        .rmd-grid.step {
            >*:not(:first-child) {
                display: none;
            }
        }

        .mobileLineItem.formHeaderContainer {
            padding: 0;

            .step {
                padding: 16px 0 16px 20px;

                .headerLabelsLeft {
                    padding: 0;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14.4px;
                    text-align: left;
                }
            }
        }

        .sectionHeaderContainer {
            padding: 0 0 0 20px;
        }

        .mobileLineItem.fieldsContainer {
            padding: 20px 20px 0;

            .lineItemRow {
                row-gap: 0;
                padding: 0;
                font-family: museo-sans;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;

                >*:nth-last-child(2),
                >*:nth-last-child(3) {
                    display: none;
                }

                .lineRowField {
                    padding-bottom: 4px;
                }

                .lineRowField:has(.summaryLabelHeader) {
                    display: flex;
                    flex-wrap: wrap;

                    .summaryLabelHeader {
                        flex: 1 1 60%;
                        padding: 0;
                        font-family: museo-sans;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14.4px;
                        text-align: left;
                    }

                    .sectionHeaderLabel,
                    .labelText {
                        margin: 0;
                        flex: 1 1 40%;
                        padding: 0;

                        div {
                            font-family: museo-sans;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: left;
                        }
                    }
                    
                    .labelDisabled,
                    .labelPriorData{
                        flex: 1 1 40%;
                    }

                    .summaryDivider {
                        flex: 1 1 100%;
                        margin: 16px 0;
                    }
                }
            }

            .lineItemRow:has(.summaryLabelHeader) {
                --rmd-grid-cols: 6;

                >:nth-child(4) {
                    min-height: auto;
                }

                .lineItemRowDivider {
                    margin: 20px 0;
                }
            }

            .lineItemRow:has(.summaryLabelHeader)+.lineItemRow {
                margin-bottom: 20px;
            }
        }

        .mobileLineItem.formLineRowContainer {
            >*:nth-last-child(2) {
                .rmd-form-message {
                    margin-bottom: 12px;
                }
            }

            >*:last-child {
                grid-column-start: 8;
            }
        }

        .mobileLineItem.formLineRowContainer.informationVerification {
            >*:nth-last-child(2) {
                margin-top: 4px;
                grid-column-start: 8;
            }

            >*:last-child {
                grid-column-start: 1;

                hr {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #Refund\ \/\ Payment\ Preferences {
        .formBuilderContainer .rmd-grid {
            --rmd-grid-cols: 1;

            .rmd-grid__cell--12 {
                grid-column-end: span 12;
            }
        }

        .collapsibleForm {
            .fieldsContainer {
                .lineItemRow {
                    column-gap: 0;
                    row-gap: 5px;
                }
            }
        }

        .expandFormContainer {
            .expandFormBtn {
                &.rmd-button--text {
                    min-width: 38px;
                    padding: 0;
                }

                .rmd-icon {
                    width: 38px;
                    height: auto;
                }
            }
        }

        .rmd-grid.formLineRowContainer {
            display: flex;
            flex-direction: column;
            padding: 0 18px;
            margin: 0;
            grid-row-gap: 0;

            .labelText {
                padding-top: 16px;
                padding-left: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
            }
        }

        .mobileLineItem.informationVerification.formLineRowContainer {
            & > :last-child {
                .rmd-divider {
                    margin-bottom: 0;
                }

                hr {
                    margin-bottom: 0;
                }
            }

            >*:nth-last-child(2) {
                grid-column-start: 8;
            }
        }

        .sectionHeaderContainer {
            padding: 0 8px 0 17px;

            .formTitle {
                margin-left: 0;
                font-size: 16px;
                line-height: 18px;
            }
        }

        .collapsibleForm .formCheckboxesHeaderContainer {
            padding-left: 18px;
        }

        .collapsibleForm .formCheckboxesHeaderContainer .multiSelectText1 {
            font-size: 12px;
            line-height: 14px;
        }

        .collapsibleForm .multiSelectBlue {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 20px 18px 0 18px;
        }

        .collapsibleForm .multiSelectBlue .multiSelectLine {
            height: auto;
            margin: 0;
            padding: 0;
        }

        .collapsibleForm .multiSelectBlue .multiSelectLine .multiSelectText2 {
            padding: 0;
        }

        .multiSelectText1 {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            text-align: left;
            margin: 0;
        }

        .sectionHeaderLabel,
        .sectionHeaderLabel.mt {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: start;
            margin-top: 20px;
        }

        .formCheckboxesHeaderContainer {
            margin: 0;
            padding: 15px 0 16px 18px;
        }

        .rmd-form-message.lastMessage {
            margin-bottom: 20px;
        }

        .rmd-grid.step {
            >*:not(:first-child) {
                display: none;
            }
        }

        .mobileLineItem.formHeaderContainer {
            padding: 0;

            .step {
                padding: 16px 0 16px 18px;

                .headerLabelsLeft {
                    padding: 0;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14.4px;
                    text-align: left;
                }
            }
        }

        .rmd-grid.formLineRowContainer.firstGroup {
            padding: 0 18px;
        }

        .mobileLineItem.fieldsContainer {
            padding: 20px 18px 0;

            .lineItemRow {
                row-gap: 0;
                padding: 0;
                font-family: museo-sans;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.4px;
                text-align: left;

                >*:nth-last-child(2),
                >*:nth-last-child(3) {
                    display: none;
                }

                .lineRowField {
                    padding-bottom: 4px;
                }

                .lineRowField:has(.summaryLabelHeader) {
                    display: block;
                    margin-bottom: 20px;

                    .summaryLabelHeader {
                        font-family: museo-sans;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14.4px;
                        text-align: left;
                        padding-top: 0;
                    }

                    .sectionHeaderLabel,
                    .labelText {
                        margin: 0;
                        padding: 20px 0;

                        div {
                            font-family: museo-sans;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: left;
                        }
                    }

                    .labelDisabled,
                    .labelPriorData{
                        padding: 20px 0;
                    }

                    .summaryDivider {
                        margin: 0;
                    }
                }
            }

            .lineItemRow:has(.summaryLabelHeader) {
                --rmd-grid-cols: 6;

                >:nth-child(4) {
                    min-height: auto;
                    margin-bottom: 0;
                }

                .lineItemRowDivider {
                    margin: 0;
                }
            }

            .lineItemRow:has(.summaryLabelHeader)+.lineItemRow {
                padding-top: 20px;
                margin-bottom: 0;
            }
        }

        .rmd-collapse:has(.summaryLabelHeader) {
            >*:last-child {
                padding: 0 18px;
                align-items: normal;
            }
        }

        .mobileLineItem.formLineRowContainer {
            .labelText {
                padding: 20px 0;
            }

            >*:nth-last-child(2) {
                margin-bottom: 4px;
            }
        }

        .bankConfirmation {
            hr {
                margin-bottom: 20px;
            }
        }
    }

    #Refund\ \/\ Payment\ Preferences {
        .rmd-collapse {
            margin-bottom: 4px !important;
        }
    }
}