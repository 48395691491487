.SuccessDialogTitle {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.07px;
    color: #2d3b59;
    text-align: center;
    font-family: Georgia;
}

.successDialogButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.WarningDialogText11 {
    font-family: georgia;
    font-size: 21px;
    font-weight: bold;
}