.entry-experience-mobile-title {
    color: white;
    font-size: 5em;
    font-family: 'Georgia';
    line-height: 100px;
    max-width: 80%;
    margin-left: 16px;
    font-weight: 400;
}

.entry-agreeement-mobile-divider {
    margin-bottom: 30px;
    margin-left: 45px;
    width: 63%;
    background-color: white;
    height: 7px;
    text-align: left;
}

.entry-mobile-please-tell-us-about {
    font-size: 40px !important;
}

.entry-modal-mobile-li-h4 {
    font-size: 35px !important;
}

.entry-modal-mobile-li-p {
    font-size: 30px !important;
}

/*Creating a custom checkbox */
.entry-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.entry-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-agreement-checkbox-white-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: #7D7F8C;
    border-radius: 1px;
}

.custom-agreement-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 8px;
    margin-top: 8px;
    height: 25px;
    width: 25px;
    background-color: #7d7f8c;
}

.entry-checkbox-container:hover input {
    background-color: #ccc;
}

.entry-checkbox-container input:checked~.custom-agreement-checkbox {
    background-color: #7DD2D3;
}

.entry-checkbox-container input:checked~.custom-agreement-checkbox-white-border {
    background-color: white;
}
/*End creating a custom checkbox */

.checkbox-confirmation-text {
    color: white;
    font-size: 28px;
    margin-left: 2rem;
    max-height: 30px;
    position: relative;
    bottom: -2px;
}


/*Changing style of scrollbar so it's more visible on mobile and changes style on desktop*/
::-webkit-scrollbar {
    width: 15px;

}

::-webkit-scrollbar-button {
    background: #ccc
}

::-webkit-scrollbar-track-piece {
    background: #eee;

}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.multipleEntryBackButton {
  width: 36px;
  height: 35px;
  font-weight: 700;
  font-size: 13px;
  color: #2E334E;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  .scrollable {
    scrollbar-width: thin !important;
  }
}

@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 7px !important;
  }

  .scrollable {
    scrollbar-width: thin !important;
  }
}

/*End changing style of scrollbar so it's more visible on mobile and changes style on desktop*/
.declineInnerContainer{
    padding: 20px 24px 5px 22px !important;
    .textContainer {
        margin-top: 20px !important;
    }
}
.secondWarningContainer {
    .warningTitle {
        font-size: 19px !important;
    }
    .warningText {
        padding-top: 15px !important;
    }
    .declineLink {
        color: #535C80;
        text-decoration: underline !important;
    }
}

.acceptContinueButton {
  padding: 8px 32px !important;
  width: 184px !important;
  height: 35px !important;
}
  
.declineButton {
  padding: 4px 22px !important;
  width: 184px !important;
  height: 35px !important;
}

@media only screen and (max-width: 479px) {
  .fixed-footer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .declineButton {
    margin: 0 !important;
  }
}

@media only screen and (min-width: 480px){
  .fixed-footer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .multipleEntryBackButton {
    margin-top: 10px;
  }
}