.discList {
    list-style-type: disc;
    margin-top: -15px;
}

.discListItem {
    list-style: disc outside none;
    display: list-item;
    margin: 0 0 .5em 1.5em;
    font-size: 12px;
}
.endowmentItem {
  font-weight: normal !important;
  font-size: 13px;
}
.endowmentItem span{
  font-weight: bold;
  font-style: italic;
}

.formSingleHeaderContainer {
    margin-top: 8px;
    min-height: 45px;
    margin: 15px -15px 0 -15px;
    border-top: solid 1px rgba(151, 151, 151, 0.25);
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
    background-color: #f7f7f7;
    color: #2e334e;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 58px;
}
  
  .fieldTextArea {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    font-family: museo-sans;
    color: #4b4b4b;
    padding: 20px 0 20px 0;
    margin-bottom: 20px;
  }
  
  .labelQuestionTitle {
    text-align: left;
    padding: 0 0 16px 17px;
    align-items: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: museo-sans;
    font-size: 13px;
    color: #2e334e;
  }
  
  .afterTable {
    padding: 17px 0 17px 17px;
  }
  
  .labelQuestionTitleDropdown {
    text-align: left;
    padding: 10px 0 0 17px;
    align-items: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: museo-sans;
    font-size: 13px;
    color: #2e334e;
  }
  
  .labelQuestionTitleDropdown2 {
    text-align: left;
    padding: 0 0 0 17px;
    align-items: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: museo-sans;
    font-size: 13px;
    color: #2e334e;
  }
  
  .labelSingleCheckbox {
    font-family: museo-sans;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4b4b4b;
    margin: -3px 0 0 17px;
  }
  
.labelTitleTextArea {
    font-family: museo-sans;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4b4b4b;
    margin: -5px 0 20px 17px;
}
  
  .singleCheckbox {
    margin-top: -20px;
  }

  .singleCheckboxMargin {
    margin: -20px 0 0 30px;
  }

  .searchOption .rmd-option {
    --rmd-list-item-horizontal-padding: .90rem;
    --rmd-list-item-vertical-padding: 0;
}

  .autoCompleteField .rmd-text-field {
    font-family: museo-sans;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4b4b4b;
  }

  .labelTitleTextAreaIndented {
    font-family: museo-sans;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4b4b4b;
    margin: -5px 0 20px 42px;
  }
  
  .fieldTextAreaIndented {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    font-family: museo-sans;
    color: #4b4b4b;
    padding: 20px 0 20px 5px;
    margin: -10px 0 40px 40px;
  }

@media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}

.labelProvider{
  margin-left: 25px;
  text-align: left;
  padding-top: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #030f1c;
  font-family: museo-sans;
}

.formBuilderContainer  {
  min-height: unset !important;

  @media (max-width: 1024px) {
    padding: 0 30px;
  }

  @media (max-width: 480px) {
    padding: 0 20px;
  }

  @media (max-width: 320px) {
    padding: 0 15px;
  }
}

/* For Safari date fields not to show current date as default */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  input[type="date"][value=""]::-webkit-datetime-edit-day-field, 
  input[type="date"][value=""]::-webkit-datetime-edit-month-field, 
  input[type="date"][value=""]::-webkit-datetime-edit-year-field {
      opacity: var(--field-opacity, 0);
  }

  input[type="date"]:focus::-webkit-datetime-edit-day-field, 
  input[type="date"]:focus::-webkit-datetime-edit-month-field, 
  input[type="date"]:focus::-webkit-datetime-edit-year-field {
    --field-opacity: 1;
  }

  /* For Safari disabled fields*/
  input:not([type="checkbox"]):disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
    -webkit-text-fill-color: var(--rmd-theme-text-disabled-on-background, #9e9e9e);
    opacity: 1;
  }
}

.uploadsActionBtns{
  color:var(--rmd-button-color)
}

.rmd-menu{
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--rmd-menu-background-color, var(--rmd-theme-surface, #fff));
  color: var(--rmd-menu-color, var(--rmd-theme-on-surface, #000));
  min-width: var(--rmd-menu-min-width, 7rem);
  z-index: var(--rmd-menu-z-index, 30);
}

.removeUpload{
  box-shadow: none;
  background-color: var(--rmd-menu-background-color, var(--rmd-theme-surface, #fff));
}