.menuItemContainer {
  display: flex;
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 0;
  border-top-width: 0;
  padding-bottom: 20px;
  z-index: 100;

  .rmd-card--full-width {
    background-color: rgba(246, 246, 246, 0.8);
  }

  .rmd-card--shadowed {
    box-shadow: unset;
  }

  .rmd-expansion-panel__header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: #2D3B59;
  }
}

.overlayMobileMenu {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000B2;
  z-index: 50;
  display: block;
}

.returnExpenderHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 100%;
  word-break: break-all;
  white-space: normal; 
}

.currentReturnHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #4B4B4B;
}

.currentReturnValue {
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #2D3B59;
}

.returnMenuExpander {
  color: #2E334E;
  width: 30px;
}

.expandedMenu {
  max-height: 250px;
  overflow-y: auto;
}

.mobileMenuName {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #2E334E;
  padding-left: 5px;
}

.mobileMenuDetailLabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.22px;
  text-align: left;
  color: #4B4B4B;
}

.mobileMenuDetailValue {
  font-weight: 400;
}

.mobileMenuDetail {
  text-align: left;
}

.menuReturnDivider {
  margin: 12px 0;
  width: 98%;
}

.menuButtonMobile {
  cursor: pointer;

  .menuItemButtonIcon {
    width: 22px;
    height: 22px;
    color: #2E334E;
  }
}

.no-scroll {
  overflow: hidden;
}

.activeReturnContainerMobile {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  text-align: left;
}

.navbarMobile {
  position: relative;
  height: 63px;
  padding: 15px 0 15px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbarMenuMobile {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.site-logo-mobile {
  float: left;
  width: 32px;
  height: 32px;
}

.menuTextMobile {
  font-family: museo-sans;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  display: inline-block;
  margin: 0px 20px;
  padding: 12px 0;
}

.headerMenuTextSelectedMobile {
  color: #09324d;
  font-weight: 600;
  padding: 12px 0;
}

.headerMenuTextMobile {
  color: #09324d;
  float: left;
  padding: 12px 0;
}

.inactiveHeaderMenuTextMobile {
  color: #d7d7d7;
  float: left;
  cursor: not-allowed;
}

.menuDivider {
  margin: 12px 50px 24px 20px;
  width: 90%;
}

.menuDivider:first-child {
  margin: 0 20px 10px 20px;
}

.menuTextMobileLogIn {
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin: 0px 20px;
  padding: 12px 0;
  color: #535C8D;
  ;
  text-decoration: underline;
}

@media screen and (min-width: 320px) {
  .App {
    .menuTextMobile {
      font-size: 18px;
      line-height: 16px;
    }
  }
}

@media screen and (min-width: 767px) {
  .App {


    .menuTextMobile {
      font-size: 18px;
      font-weight: 400;
      line-height: 16px;
    }

    .site-logo-mobile {
      float: left;
      width: 43px;
      height: 43px;
    }

    .navbarMobile {
      height: 86px;
      padding: 20px 0 20px 20px;
    }

    .menuItemContainer {
      top: 86px;
    }

    .menuItemButtonIcon {
      width: 31px;
      height: 31px;
    }

    .menuTextMobile.headerMenuTextSelectedMobile {
      font-size: 21px;
      font-weight: 600;
      line-height: 16px;
    }

    .menuDivider {
      width: 95%;
    }

    .menuReturnDivider {
      width: 99%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .App {
    .menuTextMobile {
      font-size: 21px;
      line-height: 16px;
      font-weight: 600;
      margin: 0px 15px 20px;
    }

    .menuTextMobileLogIn {
      margin: 0px 15px;
    }

    .site-logo-mobile {
      float: left;
      width: 54px;
      height: 54px;
    }

    .navbarMobile {
      height: 100px;
      padding: 23px 0 23px 30px;
    }

    .menuDivider {
      margin: 0 20px 30px 20px;
      width: 95%;
    }

    .menuItemButtonIcon {
      width: 45px;
      height: 45px;
    }

    .menuItemContainer::after {
      top: 245px;
    }
  }
}
