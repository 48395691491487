.App {
    .mobileLimitedExperienceCard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 15px;
        margin-bottom: 50px;
        padding: 25px 16px 20px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
        grid-row-gap: 20px;

        .limitedExperienceImgContainer{
            width: 71px;
            height: 71px;
            background: #F3F3F3;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 32px;
                height: 39px;
            }
        }

        h2{
            font-family: museo-sans, sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: center;
            padding: 0 10px;
        }

        p{
            font-family: museo-sans, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            margin: 0;
        }
    }
}

@media screen and (min-width : 480px){
    .App {
        .mobileLimitedExperienceCard{
            margin: 20px;
            margin-bottom: 50px;
            padding: 54px 38px;
            grid-row-gap: 26px;

            .limitedExperienceImgContainer{
                width: 115px;
                height: 115px;

                img{
                    width: 70px;
                    height: 70px;
                }
            }

            h2{
                font-size: 21px;
                line-height: 25.2px;
            }

            p{
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

@media screen and (min-width: 768px){
    .App {
        .mobileLimitedExperienceCard{
            margin: 30px 20px;
            margin-bottom: 50px;
            padding: 37px 63px;
            grid-row-gap: 31px;

            .limitedExperienceImgContainer{
                width: 149px;
                height: 149px;

                img{
                    width: 90px;
                    height: 90px;
                }
            }

            h2{
                padding: 0 100px;
                font-size: 21px;
                line-height: 25.2px;
            }

            p{
                padding: 0 17px;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

@media screen and (min-width: 1023px){
    .App {
        .mobileLimitedExperienceCard{
            margin: 30px;
            margin-bottom: 50px;
            padding: 37px 150px 91px;

            p{
                font-size: 18px;
            }
        }
    }
}