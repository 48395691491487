.noShow {
    color: #757575;
    font-family: museo-sans;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .cardGridCell {
    display: flex;
  }

  .viewAllCards {
    font-family: museo-sans;
    margin-right: -5px;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #2e334e;
  }

  .notClickable {
    cursor: not-allowed !important;
  }

  .expandCardsButton {
    background-color: #fff;
    border-radius: 50%;
  }

  .dashboardHeader {
    padding: 0 30px 0;
  }

  .categoryCell {
    min-height: auto;
  }

  .dashboardCategoriesHeader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 19px;
  }

  .dashboardCardHeaderTitle {
    flex: 1; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: flex-start;
  }

 
.dashHeaderTitle {
  color: #2e334e;
  font-family: Georgia;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
}

.dashHeaderTitle span {
  font-family: museo-sans;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #565656;
}

  .viewAllBtn {
    flex: 1; 
    display: flex; 
    justify-content: flex-end;
}

  #dashboardCategoryDivider {
    padding: 0 20px 0 19px;
  }

  .dashboardGridListCategory {
    min-height: auto;
  }

.futureContentContainer{
  padding: 110px 0;
  .futureHeadText{
      font-family: Georgia;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2e334e;
      padding-bottom: 15px;
  }
  .uploadText{
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2e334e;
      padding-bottom: 25px;
  }
  .contentText{
      color: #565656;
  }
}



