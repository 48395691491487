.cardDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.notesCardHasNotes {
    border: 3px solid #7dd2d3;
}

@media only screen and (max-width: 767px) {
    #cardNotesDialog-overlay {
        margin: 0;
    }

    #cardNotesDialog-container {
        max-width: 100%;
        margin: 0;
        align-items: flex-end;
    }

    .notesDialogContainer {
        height: calc(100vh - 63px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.rmd-dialog {
            min-width: auto;
        }

        .notesHeaderContainerMobile {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 33px 33px 7px 33px;
            background-color: #fff;

            .notesTitleMobile {
                display: flex;
                flex-direction: column;
                border-bottom: none;

                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #4B4B4B;
            }

            .notesTitleSubtext {
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
                margin: 0;
            }

            .notesCloseButtonMobile {
                position: absolute;
                top: 22px;
                right: 15px;
            }
        }

        .notesNotificationText {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;

            background-color: #F6F6F6;
            border-top: 1px solid rgba(151, 151, 151, 0.25);
            border-bottom: 1px solid rgba(151, 151, 151, 0.25);

            padding: 10px 32px;
        }

        .notesTextAreaMobile {
            border: none;
            border-width: 0;
            background-color: #fff;

            &, .rmd-textarea-container__inner, textarea {
                height: 100% !important;
                min-height: 80px;
            }

            textarea {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                padding: 15px 32px;
                border: none;
                outline: none;
            }
        }

        .notesFooterMobile {
            display: flex;
            flex-direction: column;
            background-color: #F6F6F6;
            border-top: 1px solid rgba(151, 151, 151, 0.25);
            padding: 20px 25px;
            gap: 10px;

            .notesFooterTextMobile {
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
                color: #4B4B4B;
                margin-left: 8px;
                margin-right: 8px;

                p {
                    margin: 0;
                }
            }

            .notesFooterActionsMobile {
                display: flex;
                gap: 10px;
                width: 100%;

                .formNotesCancel {
                    border-color: #2E334E;
                    border-width: 1px;
                }

                .formNotesSave {
                    background-color: #2E334E;
                    border-radius: 3px;

                    &:disabled {
                        background-color: #e8e8e8;
                        color: #606060;
                        border: none;
                    }
                }

                button {
                    width: 100%;
                    margin: 0;
                    box-shadow: none;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1439px) {
    .notesDialogContainer {
        min-width: 600px;
    }

    .notesCardHasNotes {
        border: initial;
    }
}