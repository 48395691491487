.navbarLastSaveMsg {
  margin: 30px 15px 4px 400px;
  padding-bottom: 20px;
  flex-grow: 1;
}

.navbarClientLastSaveMsg {
  margin: 30px 15px 4px 625px;
  padding-bottom: 20px;
  flex-grow: 1;
}

.lastSaveMsgFt{
  margin: 0 auto;
  padding-bottom: 20px;
  width: 100%;
  flex: 1;
}

.navbarLastSaveLabel {
  font-family: museo-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.navbarLastSaveValue {
  font-family: museo-sans;
  font-size: 12px;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

@media only screen and (max-width: 1024px) {
  .lastSaveMsgFt {
    padding: 0 50px 20px 50px;
  }
}

@media only screen and (max-width: 1249px) {
  .lastSaveMsgFt {
    padding: 0 20px 20px 20px;
  }
}