.notification-bar {
    position: relative;
    background-color:  #2E334E;
    display: flex;
    justify-content: space-between;
    height: 25px;
    align-items: center;
    padding-right: 25px;
}  

.appBarGlobalNav {
    display: flex;
    text-align: left; 
    flex: 1;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-left: 30px
}

.appBarGlobalNav ul {
    display: flex;
    align-items: center;
}
  
.appBarGlobalNav li {
    color: #ffffff;
    display: flex;
    margin: 0 20px 0 0;
    font-family: museo-sans;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    align-items: center;
}

.appBarHelpText {
    display: flex;
    text-align: right; 
    flex: 1;
    justify-content: flex-end;
    flex-direction: row;
}

.appBarHelpText ul {
    display: flex;
}
  
.appBarHelpText li {
    color: #ffffff;
    display: flex;
    margin: 0 15px 0 0;
    font-family: museo-sans;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
}

.appBarFontIconColor {
    color: #ffffff;
    font-size: 12px;
}