
.esignature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 928px;
    padding: 36px;

    div {
        padding-top: 36px;
    }

    &.error-text {
        height: 500px !important;
    }

    .docusign-iframe {
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px;
        border-color: #97979740;
        border-radius: 3px;
    }

    .return-to-dashboard-btn {
        width: 170px;
        height: 35px;
        border-radius: 3px;
    }
}