.entryExperienceMobileHeaderText {
    font-size: 37px;
    font-family: Georgia;
    font-weight: 400;
    color: #F8F8F8;
    width: 250px;
}

.mobileClaLogo {
    width: 60px;
}

.entryExperienceMobileTaxInfoText {
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 35px;
}

.entryExperienceMobileText {
    color: white;
    font-size: 28px;
}

.welcomeGrid {
  overflow: hidden;
}

.multiInfoText {
  width: 807px;
  height: 24px;
  font-family: museo-sans,sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: #f8f8f8;
  display: flex;
  line-height: 24px;
  margin-top: 30px;
}

.ContinueButton {
  width: 107px;
  height: 35px;
  margin-top: 30px;
  background-color: #FFFFFF;
  font-size: 13px;
  font-weight: 600px;
  border-radius: 5px;
}

.textIcon {
  display: flex;
  flex-direction: row;
  justify-content: left;
  overflow-wrap: break-word;
}

.dropDownIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 50;
  height: 50;
  margin: 0 0 0 auto;
  font-weight: 200;
}

@media only screen and (max-width: 350px) {
  .welcomeGrid {
    padding: 0;
  }

  .ContinueButton {
    width: 270px;
  }

  .dashboardSubMenuDropdownEntry button::after {
    left: 0 !important;
    right: -58px!important;
  }

  .dropDownIcon {
    margin-bottom: 6px;
    align-self: end;
  }

  .textIcon {
    flex-direction: column;

    span {
      width: 240px !important;
      max-height: 80px; 
      overflow: hidden; 
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (min-width: 351px) and (max-width: 479px) {
  .textIcon {
    flex-direction: column;
    gap: 10px;

    span {
      width: 260px !important;
    }
  }

  .dashboardSubMenuDropdownEntry button::after {
    left: 20px !important;
    right: -15px !important;
  }

  .dropDownIcon {
    margin-bottom: 6px;
    align-self: end;
  }
}

@media only screen and (min-width: 321px) and (max-width: 1023px) {
  .ContinueButton {
    width: 250px;
  }
}

@media only screen and (max-width: 1023px) {
  .entryExperienceDivider {
    background-color: white;
    text-align: left;
    margin-left: 0;
    margin-top: 15px;
    width: 45px;
    height: 4px !important;
  }

  .multiInfoText {
    font-family: museo-sans,sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #f8f8f8;
    display: flex;
    line-height: 23px;
    letter-spacing: -0.2px;
  }

  .dashboardSubMenuDropdownEntry button {
    font-weight: 400 !important;
  }
}

@media only screen and (min-width: 480px) {
  .textIcon {
    align-items: center;
  }
}

.custom-dropdown {
    border: none;
    border-bottom: 2px solid #000; /* Custom bottom border */
    padding: 8px 0;
  }
  
  .custom-dropdown .md-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .custom-dropdown .md-icon {
    color: #FFF; 
  }

  .dashboardSubMenuDropdownEntry button {
    font-family: museo-sans;
    font-size: 10px;
    font-weight: 600;
    color: #FFF;
    position: relative;
    padding-left: 0px;
    padding-bottom: 10px;
    border: none;
  }

  .dashboardSubMenuDropdownEntry button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 85px;
    right: -20px;
    height: 2px;
    background-color: #d8d8d8;
    opacity: 0.5;
  }


  .dashboardDropdownSelectValue {
    min-width: 66px !important;
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 10px !important;
  }

  .dashboardSubMenuDropdownLabel {
    margin-left: -15px;
  }

  .dashboardSubMenuDropdownDash button {
    font-family: museo-sans;
    font-size: 10px;
    font-weight: 600;
    color: #FFF;
    position: relative;
    padding-left: 0px;
    padding-bottom: 10px;
    border: none;
  }

  .dashboardSubMenuDropdownDash button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 73px;
    right: 1px;
    height: 2px;
    background-color: #d8d8d8;
    opacity: 0.5;
  }
  
