#Household\ Employment\ Taxes {
    --rmd-grid-gutter: 19px;

    .collapsibleForm {
        margin-bottom: 0 !important;
    }

    .formHeaderContainer {
        padding-right: 16px;

        .denseRowContainer .step {
            padding-left: 0;
            padding-right: 0;

            .headerLabelsRight {
                padding-right: 0;
            }
        }

        .headerLabelsButtonLeft {
            padding-left: 8px;
        }
    }

    .fieldsContainer .lineItemRow {
        grid-row-gap: 0;

        .householdEmploymentUpload {
            .labelText {
                display: flex;
                padding: 0;
                height: 100%;
            }
        }
    }

    .formHeaderContainer + .fieldsContainer .lineItemRow {
        padding-right: 0;
        grid-row-gap: 6px;

        .labelText {
            padding-top: 10px;
        }

        .labelTextMoney {
            padding-right: 0;
        }

        .formLineActionsBtn {
            padding-left: 8px;
        }
    }
}