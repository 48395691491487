.WarningDialogContainer {
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 12px 15px;
    width: 407px;
}

.warningDialogIcon {
    margin-right: 15px;
}

.WarningDialogText1 {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    font-family: museo-sans;
    text-align: center;
    color: #757575;
}

.dialogList {
    text-align: left;
}

.WarningDialogInnerContainer {
    padding: 32px 24px 10px 22px;
    border-radius: 4px;
    background-color: #ffffff;
}

.WarningDialogTextContainer {
    margin: 30px 0 30px 0;
}

.warningDialogCancel {
    width: 108px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #2d3b59;
    color: white;
    font-size: 10px;
    margin: 0 5px 0 5px;
}

.warningDialogConfirm {
    width: 108px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    background-color: #ffffff;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3b59;
    font-size: 10px;
    margin: 0 5px 0 5px;
}

.warningDialogSkip {
    width: 108px;
    border-radius: 3.5px;
    background-color: #f7f7f7;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3b59;
    font-size: 11px;
    margin: 0 5px 0 5px;
}