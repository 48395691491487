.email-checklist-header-container {
    min-height: 45px;
    height: 51px !important;
    padding: 0px !important;
    border-top: solid 1px rgba(151, 151, 151, 0.25);
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
    background-color: #f7f7f7;
    align-items: center;
}

.email-checklist-header-container > div:first-child {
    padding-left: 70px !important;
}

.email-checklist-header-container > div:last-child {
    padding-right: 45px !important;
}

.email-checklist-dialog {
    width: 930px !important;
}

.email-checklist-grid-container {
    min-height: 45px;
    align-items: center;
    align-content: center;
    margin: 20px 0px !important;
    padding: 0px !important;

    .grid-name-cell {
        padding-left: 6px !important;
    }

    .grid-email-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 220px;
        padding-left: 3px !important;
        span {
            margin: 0px !important;
        }
    }

    .grid-role-cell {
        padding-left: 4px !important;
    }

    .grid-lastsent-cell {
        padding-left: 2px !important;
    }

    .grid-sentby-cell {
        padding-left: 2px !important;
    }
}

.email-checklist-grid-container > div:first-child {
    padding-left: 25px !important;
}

.email-checklist-grid-container > div:last-child {
    padding-right: 45px !important;
}

.email-checklist-gridCell-padding {
    padding: 0px 45px !important;
}

.email-checklist-dialog-header {
    padding-top: 0px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-bottom: 0px !important;
}

.email-checklist-dialog-body {
    padding: 0px !important;
}

.email-checklist-grid-divider {
    margin-top: 10px !important;
}

.email-checklist-dialog-footer {
    padding-bottom: 44px !important;
}

.email-checklist-dialog-footer > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.email-checklist-header-text {
    font-size: 21px !important;
    font-weight: bold !important;
    color: #2E334E !important;
    font-family: 'Georgia';
    padding-top: 19px !important;
    margin-bottom: 0px !important;
}

.email-checklist-sub-header-text {
    font-size: 14px !important;
    font-weight: normal !important;
    margin-top: 10px !important;
    color: #4B4B4B !important;
    margin-bottom: 29px !important;
}

.email-checklist-cell-header {
    font-size: 14px !important;
    font-weight: 900 !important;
    color: #2E334E !important;
}

.gridcell-email-tooltip {
    margin: 0px !important;
}

.result-dialog {
    background-color: #f7f7f7 !important;
    padding: 12px 15px;
    border-radius: 4px;
    width: 550px !important;
    height: 250px !important;
}

.result-dialog-content {
    font-family: Georgia !important;
    font-weight: bold !important;
    font-size: 21pt !important;
    color: #2d3b59 !important;
    text-align: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.not-sent-text {
    font-style: italic !important;
    font-weight: normal !important;
}

.email-checklist-cancel-button-active {
    width: 108px;
    height: 35px;
    min-height: 35px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    background-color: #fff;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3b59;
    margin: 0 5px 0 5px;
}

.email-checklist-send-button {
    width: 108px;
    height: 35px;
    min-height: 35px;
    border-radius: 3.5px;
    background-color: #2E334E !important;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin: 0 5px 0 5px;

    &.inactive {
        background-color: #979797 !important;
        cursor: not-allowed !important;
    }
}

.email-checklist-hover-tooltip {
    margin: 10px !important;
    font-size: 14px !important;
    color: #fff;
}