.header-filter-field {
	color: black;
	background-color: white;
}

.header-filter-option {
	font-size: 13px;
	font-weight: bold;
	color: #4b4b4b;
}

.log-table td {
	text-align: left;
	min-width: 175px;
}

.log-table select {
	font-family: museo-sans;
	font-size: 10px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #595959;
}

.log-table-header-row,
.log-table-header-row th,
.log-table-footer-row,
.log-table-footer-row td {
	background-color: #1e2133;
	color: white;
}

.log-table-header-selected {
	font-family: museo-sans-display, sans-serif;
	font-size: 12px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	background-color: #1e2133;
	color: #ffffff;
}

.log-table-header {
	font-family: museo-sans-display, sans-serif;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #ffffff;
	background-color: #1e2133;
}

.log-table-cell-text {
	font-family: museo-sans;
	font-size: 12px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #072c43;
	text-align: left;
}

.log-table-header-selected .rmd-table-cell__child {
	border-bottom: 5px solid #76d2d3;
	width: 100%;
	padding-top: 7px;
	display: flex;
}

/* PAGINATION */
.log-table-footer-row .rmd-text-field-container {
	width: 60px;
}


.log-table-pagination {
	display: flex;
	justify-content: center;
	list-style: none;
	cursor: pointer;
	float: right;
	font-family: museo-sans;
	font-size: 12px;
	margin-right: 10px;
}

.log-table-pagination .previous,
.log-table-pagination .next {
	font-weight: bold;
	color: #ffffff;
}

.log-table-pagination a {
	color: #ffffff;
}

.log-table-pagination li a {
	background: none;
}

.log-table-pagination .active a {
	color: #ffffff;
	font-weight: bold;
}

.log-table-pagination .disabled a {
	color: #BDBDBD
}

.log-table-pagination .break {
	display: none;
}