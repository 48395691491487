.progressTitle {
  text-align: center;
  margin: 30px 0 0 0;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #09324d;
  font-family: museo-sans;
  word-wrap: break-word;
  white-space: pre-line;
}

.progressContainer {
  border-radius: 11px;
}