.limitedExpText {
  width: 70%;
  color: #FFFFFF;
  font-size: 21px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.limitedExpContainer {
  background-position: center;
  height: 100%;
  width: 100%;
  background-color: rgba(46, 51, 78, 0.7);
  .rmd-grid {
    grid-row-gap: 2.0rem !important;
  }
}

.limitedExpClaLogo {
  width: 77px;
}

.exploreButton {
  width: 120px;
  height: 35px;
  border-radius: 3px;
  background-color: #FFFFFF;
  font-size: 12px;
  color: #2E334E;
  font-weight: 600;
  line-height: 14.4px;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .limitedExpText {
    font-size: 16px;
    width: 90%;
  }

  .limitedExpContainer {
    background-position: center, -90px;
  }

  .limitedExpClaLogo {
    width: 51px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .limitedExpText {
    font-size: 18px;
    width: 80%;
  }
}