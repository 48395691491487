.pracDashboardSize {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.pracDashboardSizeFileExport {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

/* HEADERS */
.pracDashHeaderBar {
    background-color: #1e2133;
}

.pracDashHeaderTitle {
    font-family: museo-sans-display, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
}

.pracDashHeaderTitle:first-child {
    border-left: 50px solid #1e2133;
}

.pracDashHeaderTitle:last-child {
    border-right: 50px solid #1e2133;
}

.pracDashHeaderSelected:first-child {
    border-left: 50px solid #1e2133;
}

.pracDashHeaderSelected:last-child {
    border-right: 50px solid #1e2133;
}

.pracDashHeaderSelected {
    font-family: museo-sans-display, sans-serif;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
    /* border-bottom: 5px solid #76d2d3;
    margin: 0 50px 0 20px; */
}

.pracDashHeaderSelected .rmd-table-cell__child {
    border-bottom: 5px solid #76d2d3;
    width: auto;
    padding-top: 7px;
    display: flex;
    /*justify-content: center;*/
}

.pracDashHeaderCell {
    height: 40px;
    background-color: #f7f7f7;
}

/* BODY */

.clientTableCell {
    white-space: normal;
}

.clientInfoContainer {
    border-radius: 11px;
    background-color: #ffffff;
    border-bottom: #ffffff;
    cursor: pointer;
}

.clientInfoText {
    font-family: museo-sans;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #072c43;
    text-align: left;
    width: 150px;
}

.checkBoxNameContainer {
    display: flex;
}

.adminDashCheckbox {
    margin: -5px 0 0 0;
}

.clientInfoText:first-child {
    border-top-left-radius: 11px;
    padding-left: 36px;
}

.clientInfoText .rmd-toggle {
    justify-content: left;
}

.clientInfoText:last-child {
    border-top-right-radius: 11px;
    width: 20px;
}

.pracDashOptionMenuButton {
    position: relative;
    left: 15px;
}

.pracDashOnHover {
    font-family: museo-sans;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    /* text-align: center; */
}


/* MUI */
.MuiDataGrid-columnHeaders {
    background-color: #1e2133;
    font-family: museo-sans-display, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
}

.MuiDataGrid-columnSeparator {
    visibility: hidden;
}

.MuiDataGrid-toolbarContainer {
    position: relative;
    top: 6.7px;
    z-index: 1;
    height: 0px;
    margin-top: -6px;
}

.MuiDataGrid-menuList {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.filterButton {
    color: white;
    margin-top: -6px;

}

.filterButtonHighlight {
    color: aqua;
    margin-top: -6px;
}

.lockHeader {
    padding-left: 60px !important;
}

.MuiDataGrid-paper {
    margin-top: 17px
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard {
    color: #072c43;
    font-weight: 600;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard {
    color: #072c43;
    font-weight: 600;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiDataGrid-detailPanelToggleCell {
    padding: 5px;
    right: 10;
    margin-left: 5px;
    box-shadow: 0px 0px 5px 0px var(--primary-color);
    background-color: #f7f7f7;
    z-index: 1;
    overflow: hidden;
}

.clientInfoText.clientTableCell.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    outline: none;
    cursor: pointer;
}

.MuiDataGrid-sortIcon {
    color: #ffffff;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    color: #ffffff;
}

.addRemoveIconContainer {
    width: 20px;
    height: 20px;
    background-color: #f7f7f7;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
}

.MuiDataGrid-filterForm {
    .MuiButtonBase-root {

        background-color: #F7F7F7;

        &:hover {
            /* background-color: #ddd; */
            color: #2E334E;
            border: 1px solid #2E334E;
        }

        &:active {
            color: #fff;
            background-color: #2E334E;
        }

        .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
            color: inherit;
        }
    }
}

.file-export-button {
    /* center on line and push other element below */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

}

.taxReturnStatusNotSent {
    color: gray;
}

.taxReturnStatusSent {
    font-weight: 900;
    font-style: italic;
}

.taxReturnStatusSigned {
    color: green;
    font-weight: 900;
}

.taxReturnStatusDeclined {
    color: red;
    font-weight: 900;
}