@media screen and (max-width: 1439px) {
  .App{
      .cardContainer {
          width:100%;
          padding-top: 0px;
      }
      .cardContent{
          height: 75px;
          justify-content: start;
          padding: 15px 20px 5px;
      }
      .cardTitle{
          flex-grow: 1;
          align-items: center;
      }
      .cardIconContainer{
          margin-right: 10px;
      }
      .cardTitleContainer{
          flex-grow: 2;
      }
      .overHangImg{
          width: 45px;
          height: 45px;
      }
  }
}

@media screen and (max-width: 1439px) {
  .App{
      .cardContainer {
          width:100%;
      }
  }

}

@media screen and (min-width: 320px) {
  .App{
      .cardTitleContainer {

          p{
              font-size: 16px;
              font-weight: 600;
              line-height: 14px;
          }
      }

      .cardProgressLabelContainer {
          margin: 0 20px;
          align-items: center;

          p {
              margin: 0;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
          }
      }
  }

}

@media screen and (min-width: 768px) {
  .App{
      .cardProgressLabelContainer {

          p {
              font-size: 14px;
              font-weight: 400;
              line-height: 16.8px;
          }
      }
  }

}

@media screen and (min-width: 1440px) {
  .App{
      .cardTitleContainer p {
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
      }

      .cardProgressLabelContainer {
          p {
              margin: 5px 0 0 5px;
              font-size: 11px;
              font-weight: 700;
              line-height: normal;
          }
      }
  }

}