.dashboardNotificationBanner {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 118px;
    width: 100%;
    color: #FFFFFF;
    background-color: #24787A;
    padding-left: 90px;
    padding-right: 150px;
    text-align: left;
    position: relative;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    overflow: hidden;
    max-height: 118px;

    &Text {
        max-width: 910px;
        height: 54px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }

    .entryExperienceModalCloseBtn {
        position: absolute;
        right: 30px;
        top: 5px;
        color: #FFFFFF;
        font-weight: 50;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .dashboardNotificationBanner {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 30px 30px 37px 30px;
        height: auto;
        max-height: none !important;
        gap: 17px;
        font-weight: 400;

        &Title {
            font-family: inherit;
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }

        &Text {
            height: auto;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
        }

        .entryExperienceModalCloseBtn {
            width: 15px;
            height: 15px;
            top: 20px;
            right: 20px;

            .closeIcon {
                width: 15px;
                height: 15px;
                margin: 0;

                &:before, &:after {
                    background-color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .dashboardNotificationBanner {
        padding: 20px 20px 37px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .dashboardNotificationBanner {
        padding: 30px 20px 37px;
        gap: 10px;

        &Title {
            font-size: 14px;
        }

        &Text {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .dashboardNotificationBanner {
        padding: 26px 20px 32px 20px;
        gap: 0;
    }
}

@media only screen and (max-width: 479px) {
    .dashboardNotificationBanner {
        .entryExperienceModalCloseBtn {
            top: 11px;
            right: 15px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .dashboardNotificationBanner {
        padding: 15px 17px 18px 15px;
    }
}