.custom-date-field>input{
  background-color: rgba(0,0,0,0);
  border-radius: 3px;
  border: solid 1px rgba(151,151,151,.5);
  display: "flex";
  flex-direction: "column";
  --rmd-form-text-padding-left: 15px;
  font-size: 13px;
  font-family: museo-sans;
  font-weight: bold;
  text-align: left;
  color: #4b4b4b;
  width: 100%;
  padding: 0px 15px;
  height: 33.55px;
}
.custom-date-field>input:focus::placeholder{
  font-size: 9px !important;
  /* top: 0 !important; */
  line-height: 1rem;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  transform: translateY(-15px);
  transition: 2ms;
  /* position: relative; */
  /* width: 10px; */
  padding: 0;
  margin: 0 20px 0 0px;
}

#create-resource-dialog{
  min-width: 350px;
  height:700px;
}