.activeReturnContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
}

.headerMenuText {
  margin: 30px 15px 4px 30px;
  font-family: museo-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #09324d;
  float: left;
  cursor: pointer;
  padding-bottom: 20px;
}

.headerMenuTextSelected {
  margin: 30px 15px 4px 30px;
  font-family: museo-sans;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #09324d;
  float: left;
  cursor: pointer;
  padding-bottom: 20px;
  border-bottom: 5px solid #76d2d3;
}

.headerMenuCustomMargin {
  margin-right: 55px;
}

.inactiveHeaderMenuText {
  margin: 30px 15px 4px 23px;
  font-family: museo-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d7d7d7;
  float: left;
  cursor: not-allowed;
}

.navClientInfoContainer {
  margin-top: -20px;
}

.activeReturnName {
  margin-left: 30px;
  font-family: museo-sans;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #09324d;
  cursor: pointer;
}

.activeReturnNumber {
  margin-left: 30px;
  font-family: museo-sans;
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #4b4b4b;
  cursor: pointer;
}

.navbarIconButton {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 25px 0 0;
}

.navbarBellBadge {
  left: 5px;
  background-color: red;
}

.navbar {
  position: relative;
  height: 81px;
  padding: 12px 0px 18px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  align-items: center;
}

.navbarMenu {
  display: flex;
  margin-bottom: -20px;
  flex-grow: 1;
}

.site-logo {
  float: left;
  margin: 10px 0 0 20px;
  width: 35px;
  height: 35px;
}