.restful-table-header-bar {
    background-color: #1e2133;
}

.restful-table-header {
    font-family: museo-sans-display, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
		max-width: 200px;
		min-width: 150px;
}

.restful-table-header-selected {
    font-family: museo-sans-display, sans-serif;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
}

.restful-table-header-selected .rmd-table-cell__child {
    border-bottom: 5px solid #76d2d3;
    width: auto;
    padding-top: 7px;
    display: flex;
}

.restful-table-cell {
    /* white-space: normal; */
}

.restful-table-cell-text {
    font-family: museo-sans;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #072c43;
    text-align: left;
    width: 150px;
		max-width: 300px;
}

.restful-table-cell-actions {
    min-width: 156px;
}

.pracDashHeaderCell {
    height: 40px;
    background-color: #f7f7f7;
}

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    float: right;
    font-family: museo-sans;
    font-size: 12px;
    margin-right: 10px;
}

#create-resource-button {
    color: white;
}

.action-header-cell {
    text-align: center;
}

.organizers-search-button {
    margin-left: 5px;
}

.org-search-form  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.org-search-form input {
    padding: 5px 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px;
    width: 200px;
}

.org-search-form button {
    padding: 5px 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px;
    background-color: white;
    color: #1e2133;
    cursor: pointer;
}

/* add hover to the button */
.org-search-form button:hover {
    background-color: #1e2133;
    color: white;
}
  