.tableHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: end;
}
.tableHeader *:nth-child(odd){
    justify-self: start;
}
.tableHeader *:nth-child(even){
    justify-self: end;
}
.orgTable > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.orgTable > div *:nth-child(odd){
    justify-self: start;
}
.orgTable > div *:nth-child(even){
    justify-self: end;
}

.organizer > h1,h2,h3 {
    font-family: Georgia;
    margin: 0px;
}
.organizer h3 {
    text-align: left;
}
.organizer h4 {
    margin: 0px;
    text-align: left;
}
.organizer h2 {
    margin-top: 20px;
}
.organizer {
    padding: 24px 25px;
    background-color: white;
    width: 816px;
    margin: 0 auto;
}

.saveBtn {
    height: 50px;
    width: 250px;
    background-color: #34425D;
    color: white;
    border-radius: 15px;
    display: block;
    margin: 0 auto;
    border: none;
}
.saveBtn:hover {
    cursor: pointer;
    background-color: #6CAFB6;
}
p {
    font-size: 11px;
    text-align: left;
}

/* Charitable Contributions Stuff */

/* cash */
.cashContributionsTableHeader, .cashContributionsTable, .cashContributionsTableTotal {
    display: grid;
    grid-template-columns: 4.5fr repeat(3, 1fr);
}

.cashContributionsTable *:not(:first-child) {
    justify-self: end;
}
.cashContributionsTable *:first-child {
    justify-self: start;
}

.cashContributionsTableTotal *:not(:first-child) {
    justify-self: end;
}
.cashContributionsTableTotal *:first-child {
    justify-self: start;
}

.cashContributionsTableHeader *:not(:first-child) {
    justify-self: end;
}
.cashContributionsTableHeader p {
    font-weight: bold;
}
/* non cash */
.nonCashContributionsTableHeader, .nonCashContributionsTable {
    display: grid;
    grid-template-columns: 1.5fr repeat(6, 1fr);
}
.nonCashContributionsTableHeader {
    font-weight: bold;
}
.nonCashContributionsTableHeader *:not(:first-child) {
    justify-self: end;
}
.nonCashContributionsTable *:first-child {
    justify-self: start;
}
.nonCashContributionsTable *:not(:first-child) {
    justify-self: end;
}
.nonCashContributionsTableTotal {
    display: grid;
    grid-template-columns: 4.5fr repeat(3, 1fr);
}
.nonCashContributionsTableTotal *:not(:first-child) {
    justify-self: end;
}
.nonCashContributionsTableTotal *:first-child {
    justify-self: start;
}
/* stocks */
.stockContributionsTableHeader, .stockContributionsTable {
    display: grid;
    grid-template-columns: 2.5fr repeat(5, 1fr);
}
.stockContributionsTableHeader {
    font-weight: bold;
}
.stockContributionsTableHeader *:not(:first-child) {
    justify-self: end;
}
.stockContributionsTable *:first-child {
    justify-self: start;
}
.stockContributionsTable *:not(:first-child) {
    justify-self: end;
}
.stockContributionsTableTotal {
    display: grid;
    grid-template-columns: 5.5fr 1fr 1fr;
}
.stockContributionsTableTotal *:not(:first-child) {
    justify-self: end;
}
.stockContributionsTableTotal *:first-child {
    justify-self: start;
}
/* Miles Contributions */
.milesContributionsTableHeader, .milesContributionsTable {
    display: grid;
    grid-template-columns: 4.5fr repeat(3, 1fr);
}
.milesContributionsTableHeader *:not(:first-child) {
    justify-self: end;
}
.milesContributionsTableHeader *:first-child {
    justify-self: start;
}
.milesContributionsTable *:not(:first-child) {
    justify-self: end;
}
.milesContributionsTable *:first-child {
    justify-self: start;
}

/* About you */
.taxPayerInfoTable {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 44px);
    align-items: center;
}
.taxPayerInfoTable * {
    justify-self: center;
}
.aboutYouTwoColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, 44px);
}
.aboutYouTwoColumn *:nth-child(odd){
    justify-self: start;
    align-self: center;
}
.aboutYouTwoColumn *:nth-child(even){
    justify-self: end;
}
.addressTable {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 44px 44px;
    gap: 10px;
}
.addressTable input {
    width: 100%;
}

/* Dependents */
.dependentsInfoTableHeader, .dependentsInfoTable {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 10px;
}
.dependentsInfoTableHeader {
    font-weight: bold;
}
.dependentsInfoTable input {
    width: 100%;
}
.dependentsTwoColumn {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, 44px);
}
.dependentsTwoColumn *:nth-child(odd){
    justify-self: start;
    align-self: center;
    text-align: left;
}
.dependentsTwoColumn *:nth-child(even){
    justify-self: end;
}

/* Schedule C */
.businessInfoTable {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr;
    column-gap: 10px;
}
.businessInfoTable input {
    width: 100%;
}
.businessIncomeTableHeader {
    display: grid;
    grid-template-columns: 5.5fr 1fr 1fr;
}
.businessIncomeTableHeader {
    font-weight: bold;
}
.businessIncomeTableHeader *:not(:first-child) {
    justify-self: end;
}
.businessIncomeTable {
    display: grid;
    grid-template-columns: 5.5fr 1fr 1fr;
}
.businessIncomeTable *:first-child{
    justify-self: start;
}
.businessIncomeTable *:nth-child(3n-2){
    justify-self: start;
}
.businessIncomeTable * {
    justify-self: end;
}
.businessIncomeTableTotal {
    display: grid;
    grid-template-columns: 5.5fr 1fr 1fr;
}
.businessIncomeTableTotal *:first-child{
    justify-self: start;
}
.businessIncomeTableTotal *:not(:first-child) {
    justify-self: end;
}
.businessEquipmentTableHeader {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr 1fr;
}
.businessEquipmentTableHeader *:first-child {
    justify-self: start;
}
.businessEquipmentTableHeader *:not(:first-child) {
    justify-self: end;
}
.businessEquipmentTable {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr 1fr;
}
.businessEquipmentTable *:first-child{
    justify-self: start;
}
.businessEquipmentTable *:nth-child(7n-6){
    justify-self: start;
}
.businessEquipmentTable * {
    justify-self: end;
}

/* Refund Preferences */
.bankInfoTableHeader {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    font-weight: bold;
}
.bankInfoTableHeader *:first-child {
    justify-self: start;
}
.bankInfoTableHeader *:not(:first-child) {
    justify-self: end;
}
.bankInfoTable {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
}
.bankInfoTable *:first-child{
    justify-self: start;
}
.bankInfoTable *:nth-child(4n-3){
    justify-self: start;
}
.bankInfoTable * {
    justify-self: end;
}
.bankInfoTable div, input {
    width: 100%;
}

/* Tax Payments */
.taxPaymentsTableHeader {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    font-weight: bold;
}
.taxPaymentsTableHeader *:first-child {
    justify-self: start;
}
.taxPaymentsTableHeader *:not(:first-child){
    justify-self: end;
}
.taxPaymentsTable {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: 44px;
    align-items: center;
}
.taxPaymentsTable *:first-child {
    justify-self: start;
}
.taxPaymentsTable *:nth-child(5n-4) {
    justify-self: start;
}
.taxPaymentsTable * {
    justify-self: end;
}

/* Retirement Contributions */
.retirementTableHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    font-weight: bold;
}
.retirementTableHeader *:first-child {
    justify-self: start;
}
.retirementTableHeader * {
    justify-self: end;
}
.retirementTable {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    grid-auto-rows: 44px;
    align-items: center;
}
.retirementTable *:first-child{
    justify-self: start;
}
.retirementTable *:nth-child(5n-4){
    justify-self: start;
}
.retirementTable * {
    justify-self: end;
}
.retirementTableTotal {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
}
.retirementTableTotal *:first-child {
    justify-self: start;
}
.retirementTableTotal * {
    justify-self: end;
}


/*inputs*/
.orgExportInput {
    border: 2px solid lightgray;
    border-radius: 5px;
    padding: 3px;
    background: none;
    text-align: left;
    display: block;
    height: 35px;
    overflow-wrap: break-word;
}

.orgExportEmailInput {
    /* border: 2px solid lightgray;
    border-radius: 5px;
    padding: 3px;
    background: none;
    text-align: left;
    display: block; */
    /* height: 35px; */
    /* min-width: 100px; */
    /* height: 70px; */
    /* max-width: 120px; */
    margin-bottom: 10px;
    font-size: 8px;
    resize: auto;
    flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.orgExportInput input {
    padding: 0;
}

.orgExportInput[type=number] {
    text-align: right;
}
.orgExportLabel {
    background-color: white;
    position: relative;
    top: -7px;
    display: table;
    float: right;
    font-size: 10px;
}
.orgExportTopLabel {
    background-color: white;
    top: 7px;
    display: table;
    text-align: left;
    position: relative;
    font-size: 10px;
}
.yesNoInput {
    width: 75px;
}
.dateInput {
    width: 90px;
}

/* Utilities */
.bold {
    font-weight: bold;
    font-size: 14px;
}
.indentLeft {
    padding-left: 10px;
}
.yesNoQuestion {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-auto-rows: 44px;
}
.yesNoQuestion *:nth-child(odd){ 
    justify-self: start;
    align-self: center;
    text-align: left;
}
.yesNoQuestion input {
    width: 100%;
}
.totalBar {
    font-weight: bold;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
}

/* begin: New css for Foreign Assets */
.barcode{
	text-align: left;
	
}
.lblFullName{
	text-align: right;
	
}

.lblQuestionAsk{

	margin-left: 10px;
	font-size: 12px;
	font-weight:bold;
				
}


.lblAllApply{
	margin-left: 10px;
	font-size: 12px;
}

.twoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(2, 10px);
	margin-left: 10px;

}
.twoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.twoColumn *:nth-child(even){
	justify-self: end;
}
.foreignAssetsTwoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(5, 40px);
	margin-left: 10px;

}
.foreignAssetsTwoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.foreignAssetsTwoColumn *:nth-child(even){
	justify-self: end;
}
.foreignAssetsAddressTable {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	grid-template-rows: 40px 40px;
	gap: 10px;
	margin-bottom: 20px;
	margin-left: 10px

}
.foreignAssetsAddressTable input {
	width: 100%;

}
.accountDetailsTwoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(4, 40px);
	margin-left: 10px;

}
.accountDetailsTwoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.accountDetailsTwoColumn *:nth-child(even){
	justify-self: end;
}
.addressTableInformation {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	grid-template-rows: 25px 25px;
	gap: 10px;
	margin-left: 10px
}
.addressTableInformation input {
	width: 100%;

}
.accountOverviewTable {
	display: grid;
	grid-template-columns: repeat(2, 2fr) repeat(4, 1fr);
	grid-template-rows: 5px 1px;
	gap: 10px;
	margin-left: 10px
	

}
.accountOverviewTable2 {
	display: grid;
	grid-template-columns: repeat(2, 2fr) repeat(4, 1fr);
	grid-template-rows: 10px 10px;
	gap: 10px;
	margin-bottom: 20px;
	margin-left: 10px
	

}
.accountOverviewTable2 input {
	width: 100%;

}
/* end: New css for Foreign Assets */


/* begin: New css for passthrough income */
hr{
	margin-left:10px
}
.lblPassthroughIncome{
	font-weight: bold;
}			
.lblQuestionHint{
	font-size: 12px;
}


.incomeTwoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(3, 40px);
	margin-left: 10px;

}
.incomeTwoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.incomeTwoColumn *:nth-child(even){
	justify-self: end;
}
.OverviewExpensesTable {
	display: grid;
	grid-template-columns: 1fr ;
	margin-left: 10px;
	font-size: 12px;


}
.yearAmount{
	display: grid;
	grid-template-columns:  1fr 1fr;
	margin-left: 900px;
	font-size: 12px;
	
	
}
.incomeThreeColumn {
	display: grid;
	grid-template-columns: 2fr .5fr .42fr;
	grid-template-rows: 30px 30px;
	gap: 10px;
	margin-bottom: 20px;
	margin-left: 10px

}
.incomeThreeColumn input {
	width: 100%;

}
.businessThreeColumn {
	display: grid;
	grid-template-columns: 2fr .5fr .5fr;
	grid-template-rows: 10px 10px;
	gap: 10px;
	margin-left: 10px;
	background: lightgray;

}
.businessThreeColumn input {
	width: 100%;

}
.vehicleTable {
	display: grid;
	grid-template-columns: repeat(1, 2fr) repeat(1, .2fr);
	grid-template-rows: 5px 1px;
	gap: 10px;
	margin-left: 10px;
	font-size: 12px;
	

}

.vehicleInputTable {
	display: grid;
	grid-template-columns: 2fr  1fr;
	margin-bottom:-2px;
	gap: 710px;
	margin-left: 10px;

}
.vehicleInputTable input {
	width: 100%;

}
.yearOverviewTable {
	display: grid;
	grid-template-columns:  1fr 1fr;
	gap: 200px;
	margin-left: 10px;
	font-size: 12px;
	float:right;
	


}
.hr{
	margin-top:20px;
}
.questionIncomeThreeColumn {
	display: grid;
	grid-template-columns: 2fr .5fr .5fr;
	gap:88px;
	margin-bottom: -2px;
	margin-left: 10px;


}
.questionincomeThreeColumn input {
	width: 100%;

}
.passthroughQuestionTwoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(2, 40px);
	margin-left: 10px;
	gap:10px;

}
.passthroughQuestionTwoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.passthroughQuestionTwoColumn *:nth-child(even){
	justify-self: end;
}
.milesThreeColumn {
	display: grid;
	grid-template-columns: 2fr .5fr .42fr;
	gap:10px;
	margin-bottom: -2px;
	margin-left: 10px;


}
.milesThreeColumn input {
	width: 100%;

}
.amountYears{
	margin-left:170px;
}
.amountMilesYears{
	margin-left:80px;
	float:right;
}
.amountFeeYears{
	margin-left:120px;
}
.amountUnreimbursedYears{
	margin-left:144px;
}

/* end: New css for passthrough income */

/* begin: New css for foreign income */
.topSectionHeader {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-left: 10px;
	gap:10px;
	
}
.practitionerLabel {
    background-color: white;
    position: relative;
    top: -7px;
    display: table;
	margin-left: 125px;
    font-size: 10px;
}
.fiveColumn {
	display: grid;
	grid-template-columns: repeat(3, 2fr) repeat(2, 1fr);
	gap: 10px;
	margin-left: 10px
	

}
.fiveColumn input {
	width: 100%;

}
.employmentDetails {
	display: grid;
	grid-template-columns: repeat(1, 2fr) repeat(5, 1fr);
	grid-template-rows: 5px 1px;
	gap: 10px;
	margin-left: 10px
	

}
.employmentDetails input {
	width: 100%;

}
.twoRowTwoColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(2, 40px);
	margin-left: 10px;

}
.twoRowTwoColumn *:nth-child(odd){
	justify-self: start;
	align-self: center;


}
.twoRowTwoColumn *:nth-child(even){
	justify-self: end;
}
.twoRowFourColumn {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	margin-left: 10px
}
.twoRowFourColumn input {
	width: 100%;

}
.lblTitles{
	font-size:20px;
}
/* end: New css for foreign income */		


/* CSS for org export renderer */

.orgExportTableHeader {
    /* padding: 16px 40px 20px 30px; */
    border-bottom: solid 1px black;
    align-items: center;
}

.clientDetailsContainer {
    color: #000000;
}

.clientDetailsContainer span:nth-child(1) {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.clientDetailsContainer span:nth-child(2) {
    font-family: museo-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.formBodyMargin {
    margin-top: 30px;
}
.send-checklist-cell {
    margin-top: 15px;
    font-family: museo-sans, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
}
/* Export Renderer (Org Export) */
.pagebreak {
    margin-bottom: 20px;
    overflow: hidden;
}

.pagebreak .expandableFieldsContainer {
    padding: 4px 0 0 0;
}

.pagebreak .formLineRowContainer {
    margin: 0;
    /* padding: 10px 16px 10px 16px; */
}

.pagebreak .defaultGroup {
    padding: 0 16px 16px 16px;
    margin: 0 26px -50px;
}

.pagebreak .denseRowSectionContainer {
    margin: 0 -30px;
    padding: 0;
}

.pagebreak .rmd-form-message__message {
    margin: 4px 0;
}

.pagebreak .lineItemRow {
    padding: 20px 18px 0 0;
    /* margin-right: 25px; */
    /* height: 50px; */
}

.pagebreak .lineItemRowHeight {
    height: auto;
}

.pagebreak .multiSelectLine {
    height: auto;
}

/* .pdfSectionsContainer .lineItemRow {
    height: 50px;
} */

.pagebreak .fieldsContainer {
    padding: 0;
}

.pagebreak .rmd-form-message {
    max-height: none;
}

.pagebreak .labelText {
    word-break: break-word;
    white-space: pre-line;
    padding-top: 10px;
}

.pagebreak .labelPriorData {
    word-break: break-word;
    white-space: pre-line;
}

.pagebreak .denseLineItemRow {
    padding: 10px 0 0;
}

.pagebreak .sectionHeaderContainer {
    height: auto;
}

/* @media screen and (max-width: 816px) {
  #divToPrint .collapsibleForm {
    display: flex;
    flex-shrink: 0;
  }
} */

.pagebreak {
    .rmd-grid__cell--1 {
        grid-column-end: span 1;
        grid-column-start: auto;
    }

    .rmd-grid__cell--2 {
        grid-column-end: span 2;
        grid-column-start: auto;
    }

    .rmd-grid__cell--3 {
        grid-column-end: span 3;
        grid-column-start: auto;
    }

    .rmd-grid__cell--4 {
        grid-column-end: span 4;
        grid-column-start: auto;
    }

    .rmd-grid__cell--5 {
        grid-column-end: span 5;
        grid-column-start: auto;
    }

    .rmd-grid__cell--6 {
        grid-column-end: span 6;
        grid-column-start: auto;
    }

    .rmd-grid__cell--7 {
        grid-column-end: span 7;
    }

    .rmd-grid__cell--8 {
        grid-column-end: span 8;
        grid-column-start: auto;
    }

    .rmd-grid__cell--9 {
        grid-column-end: span 9;
    }

    .rmd-grid__cell--10{
        grid-column-end:span 10;
    }

    .rmd-grid__cell--11{
        grid-column-end:span 11;
    }

    .rmd-grid {
        --rmd-grid-cols:12;
        row-gap: 0;
    }

    .rmd-collapse {
        padding: 0 6.5px;
    }

    .sectionPadding {
        padding-right: 20px;
    }
}

.k1Grid {
    --rmd-grid-cols:12;
}

.notesHeaderTitle {
    display: flex;
    justify-content: center;
}

.notesGrid {
    & div {
        display: flex;
        justify-content: space-between;
    }

    & div *:nth-child(odd) {
        flex: 2;
        justify-content: start;
        text-align: left;
    }

    & div *:nth-child(even) {
        flex: 1;
        justify-content: end;
        text-align: right;
    }
}