.newFileTagSpace {
    padding-left: 11px;
    margin-left: 8px;
}

.docManagerCell-name-text {
    white-space: normal !important;
    overflow-wrap: break-word !important;
    overflow-wrap: break-word !important;
    max-width: 50% !important; 
}