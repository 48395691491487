.WarningDialogContainer {
  border-radius: 4px;
  background-color: #f7f7f7;
  padding: 12px 15px;
  width: 407px;
}

.WarningDialogInnerContainer {
  padding: 32px 24px 10px 22px;
  border-radius: 4px;
  background-color: #ffffff;
}
  
.SuccessDialogTitle {
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.07px;
  color: #2d3b59;
  text-align: center;
  font-family: Georgia;
}

.WarningDialogTextContainer {
  margin: 30px 0 30px 0;
}

.WarningDialogText1 {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  font-family: museo-sans;
  text-align: center;
  color: #757575;
}

.successDialogButton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.formNotesSaveActive {
  width: 98px;
  height: 28px;
  border-radius: 3px;
  border: solid 1.5px #2d3b59;
  font-family: museo-sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #2d3b59;
  color: white;
  font-size: 13px !important;
  margin: 0 18px 0 10px;
}

#custom-dialog {
  .downloadComplete {
    font-family: Georgia;
    font-size: 21px;
    color: #2D3B59;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1024px) {
  .rmd-dialog-container {
    margin-left: auto;
    margin-right: auto;
  }

  .WarningDialogContainer {
    padding: 8px;

    .WarningDialogInnerContainer {
      padding-bottom: 30px;

      .WarningDialogTextContainer {
        padding: 0 26px;
        margin: 30px 0 40px 0;

        .WarningDialogText1 {
          font-size: 16px !important;
          line-height: 19px;
          font-weight: 400 !important;
        }
      }
    }
  }

  .submitToClaConfirmationDialog {
    .WarningDialogInnerContainer {
      padding: 32px 10px 10px 10px;
    }

    .WarningDialogTextContainer {
      padding: 0 12px;
    }

    .WarningDialogText1 {
      font-size: 14px !important;
      line-height: 19px;
      text-align: left;
    }

    .WarningDialogMainText {
      margin-bottom: 19px;
    }

    .WarningDialogListItem {
      display: list-item;
      list-style-type: decimal;
      margin-left: 15px !important;
      margin-right: 0 !important;
    }

    .warningDialogButton {
      display: flex;
      gap: 5px;
      padding-left: 64px;
      padding-right: 64px;
    }

    & .warningDialogCancel,
    & .warningDialogConfirm {
      margin: 0;
      font-size: 13px;
      line-height: 16px;
    }

    .warningDialogCancel {
      background: #fff;
      color: #2E334E;
      flex-grow: 1;
    }

    .warningDialogConfirm {
      background: #2E334E;
      color: #fff;
      flex-grow: 12;
    }
  }

  .submitToClaSuccessDialog {
    .SuccessDialogTitle {
      padding: 0 20px;
      line-height: 24px;
    }

    .SuccessDialogText {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      padding: 0 6px;
      color: #4B4B4B;
    }

    .successSurveyCls {
      font-weight: 600;
      text-decoration: underline;
      color: #535C8D;
    }

    .formNotesSaveActive {
      width: 135px;
      text-transform: capitalize;
      font-size: 13px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  .rmd-dialog-container {
    max-width: 460px;

    .submitToClaSuccessDialog {
      .SuccessDialogText {
        padding: 0 29px;
      }
    }
  }

  .multipleFileErrorMessageDialog {
    .SuccessDialogTitle {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 35px;
      }
    }

    .WarningDialogInnerContainer {
      padding: 21px 2px;

      .WarningDialogTextContainer {
        margin: 14px 0 24px;
        padding: 0;

        .WarningDialogText1 {
          padding: 0 8px;

          .docManagerFileList {
            @media only screen and (max-height: 678px) {
              max-height: 245px !important;
            }
            @media only screen and (max-height: 568px) {
              max-height: 200px !important;
            }
            @media only screen and (max-height: 525px) {
              max-height: 150px !important;
            }

            &>.rmd-grid {
              grid-row-gap: 6px;
              padding-left: 0;
              padding-right: 0;

              &>.rmd-grid__cell {
                padding: 0 !important;
              }
            }

            .docManagerFileName {
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              margin-top: 5px;
              margin-bottom: 4px;
            }

            .docManagerFileMessage {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .docManagerGridContainer {
      display: none;
    }

    #multipleUploadMessage {
      font-weight: 400;
      padding: 0 4px;
    }

    .successDialogButton {
      padding-bottom: 8px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .rmd-dialog-container {
    max-width: 352px;

    .WarningDialogContainer {
      .warningDialogButton {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .submitToClaConfirmationDialog {
      .WarningDialogInnerContainer {
        padding: 16px 32px 10px 32px;
      }
    }

    .submitToClaSuccessDialog {
      .SuccessDialogTitle {
        padding: 0 56px;
      }

      .SuccessDialogText {
        padding: 0 6px;
      }
    }

    .multipleFileErrorMessageDialog {
      .docManagerFileList {
        max-height: 345px !important;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .rmd-dialog-container {
    max-width: 286px;

    .WarningDialogContainer {
      .WarningDialogInnerContainer {
        .WarningDialogTextContainer {
          padding: 0;

          .WarningDialogText1 {
            font-size: 14px !important;
          }
        }
      }
    }

    .submitToClaConfirmationDialog {
      .WarningDialogInnerContainer {
        padding: 32px 10px 10px 10px;

        .WarningDialogTextContainer {
          padding: 0 14px;
        }
      }
    }

    .submitToClaSuccessDialog {
      .SuccessDialogTitle {
        padding: 0 20px;
      }
    }

    .multipleFileErrorMessageDialog {
      .docManagerFileList {
        max-height: 245px !important;
      }
    }
  }
}