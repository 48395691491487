@media only screen and (max-width: 1024px) {
    .App {
        .formUploadContainer {
            margin: 30px 16px;
            border-width: 1px;
            flex: 0;
        }

        .formUploadHeaderContainer {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: auto;
            padding: 16px;
            gap: 16px;

            .sectionHeaderTitle {
                margin-left: 0;
                gap: 8px;

                .sectionHeaderTitleContainer {
                    align-items: center;
                    gap: 4px;
                }

                .formTitle {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 700;
                    margin: 0;
                }

                .helpIcon {
                    visibility: visible;
                    margin: 0;
                    font-size: 16px;
                }
            }

            .formUploadSubTitle {
                font-size: 12px;
                line-height: 14px;
                margin: 0;
            }

            .expandFormContainer {
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: 16px;

                & > button {
                    width: 100%;

                    &:first-child {
                        padding: 0;
                    }

                    form {
                        width: 100%;
                    }
                }

                .rmd-button--text {
                    width: 100%;
                    margin: 0;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                }
            }
        }

        .uploadDrawerContainer {
            padding: 0;

            .uploadDrawerDivider {
                margin-left: 16px;
                margin-right: 16px;
                width: calc(100% - 32px);
            }

            .uploadDrawerHeaderContainer {
                min-height: auto;
            }

            .uploadedListContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-height: none;

                .fileNameRow {
                    padding-top: 11px;
                    position: relative;

                    .deleteIcon {
                        position: absolute;
                        top: 12px;
                        right: 16px;
                        width: 14px;
                        height: 16px;

                        @media (min-width: 480px) and (max-width: 767px) {
                            width: 20px;
                            height: 23px;
                        }

                        @media (min-width: 768px) {
                            width: 22px;
                            height: 25px;
                        }
                    }

                    &>.rmd-grid__cell {
                        max-width: calc(100% - 20px);
                        text-wrap: nowrap;

                        @media (min-width: 480px) and (max-width: 767px) {
                            max-width: calc(100% - 26px);
                        }

                        @media (min-width: 768px) {
                            max-width: calc(100% - 28px);
                        }
                    }
                }

                .fileViewDetails {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 600;
                    font-family: museo-sans;
                    margin-left: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .secondaryText {
                    color: #565656;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: left;
                    padding-bottom: 11px;

                    .rmd-grid__cell {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-wrap: nowrap;
                    }
                }

                .uploadsPagination {
                    display: flex;
                    justify-content: center;
                    margin: 12px auto 20px auto;

                    .MuiPaginationItem-text {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 15px;
                    }

                    .Mui-selected {
                        background-color: transparent;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .App {
        .formUploadHeaderContainer {
            flex-direction: row;
            align-items: center;

            .sectionHeaderTitle {
                flex: 2;

                .formTitle {
                    font-size: 18px;
                    line-height: 20px;
                }

                .formUploadSubTitle {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .expandFormContainer {
                min-width: 255px;

                & > button {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        .uploadDrawerContainer {
            .uploadedListContainer {
                .fileNameRow {
                    padding-top: 12px;
                }

                .fileViewDetails {
                    font-size: 18px;
                    line-height: 22px;
                }

                .secondaryText {
                    padding-bottom: 15px;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }
}