.page-instructions {
    align-self: center;
    padding-left: 10vw;
    padding-right: 10vw;
}

.page-result-title {
    padding: 1vh 5vw;
}

.page-result-text {
    padding: 1vh 10vw;
    white-space: pre-wrap;
}