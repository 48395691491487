.imgContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 144px;
  margin: 1px 0 0;
  padding: 30px 0 38px 42px;
  background-color: #e9e9e9;
  background-image:
    linear-gradient(176deg,
      rgba(251, 197, 90, 0.77) 8%,
      rgba(255, 255, 255, 0.008841036414565795) 8.5%),
    linear-gradient(5deg,
      rgba(125, 210, 211, 0.77) 12%,
      rgba(255, 255, 255, 0.008841036414565795) 12.5%),
    linear-gradient(71deg,
      rgba(46, 51, 78, 1) 39%,
      rgba(255, 255, 255, 0.008841036414565795) 39.1%),
    linear-gradient(90deg,
      rgba(255, 255, 255, 0.008841036414565795) 0%,
      rgba(45, 59, 89, 1) 0%);
  background-repeat: no-repeat;
}

.clientInfoHeaderContainer {
  flex: 1.5;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.dashboardTitle {
  flex: 1;
  text-align: left;
}

.dashboardTitle #headerInfo,
#headerInfo span {
  font-family: Georgia;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.dashboardTitle #headerInfo {
  margin-left: 50px;
}

.dashboardTitle #clientId,
#clientId span {
  margin-top: -15px;
  font-family: Georgia;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f7f7f7;
}

.clientEmail {
  margin-top: -15px;
  margin-left: 130px;
  font-family: Georgia;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f7f7f7;
}

.formPageClientEmail {
  margin-top: -15px;
  margin-left: 50px;
  font-family: Georgia;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f7f7f7;
}

.dashboardTitle #clientId {
  width: 250px;
  font-weight: 500;
  margin-left: 50px;
  display: flex;
  white-space: nowrap;
}

.dashboardTitle #clientId .rmd-text-field-container--outline {
  height: 20px !important;
}

.dashboardTitle #clientId .rmd-text-field-container--outline #select-scroll-tab-display-value {
  color: #000;
  margin: 0px;
  padding: 0px 30px 0px 12px;
}

.dashboardTitle #clientId .rmd-text-field-container--outline .rmd-text-field-addon {
  margin: 0px;
  right: 0px;
}

.successSurveyCls {
  outline: none;
  border: none;
  color: #0000EE;
}

.imgBarCode {
  color: #FFFF;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  margin: 1px 0 0;
}

.grid-container {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px; */
  justify-content: space-between;
}

.cardLink {
  outline: none;
  border: none;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: 800;
}

.disabledCard {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.iconCard {
  padding: 0 0 0 0 !important;

  .iconImg {
    padding: 0 0 0 0 !important;
    padding-top: 9px !important;
  }

  .iconText {
    text-align: center;
    padding-top: 6px !important;
  }
}

.checklistKabob {
  width: 147px !important;
}

@media only screen and (max-width: 950px) {
  .submitToCla {
    display: flex !important;
  }

  .checklistCard.dashboardHeaderCard {
    display: flex !important;
  }

  /* TODO: Uncomment if needed for mobile */
  /*.summaryPDFCard.dashboardHeaderCard {*/
  /*  display: flex !important;*/
  /*}*/

  .notes .dashboardHeaderCard {
    display: flex !important;
  }

  .completeCard.dashboardHeaderCard {
    display: flex !important;
  }
}

/* for iPad Pro 13 responsiveness */
@media only screen and (max-width: 1032px) {
  .notApplicable.dashboardHeaderCard {
    display: none !important;
  }
}

/* for iPad Pro 13 responsiveness */
@media only screen and (min-width: 1033px) {
  .notApplicable.dashboardHeaderCard {
    display: flex !important;
  }
}

@media only screen and (max-width: 1024px) {
  .App {
    .imgContainer {
      height: 119px;
      padding: 0;
      padding-left: 33px;

      background-image: linear-gradient(171deg,
      rgba(251, 197, 90, 0.77) 6%,
      rgba(255, 255, 255, 0.008841036414565795) 7%),
      linear-gradient(6deg,
              rgba(125, 210, 211, 0.77) 8.5%,
              rgba(255, 255, 255, 0.008841036414565795) 9.5%),
      linear-gradient(71deg,
              rgba(46, 51, 78, 1) 39%,
              rgba(255, 255, 255, 0.008841036414565795) 39.1%),
      linear-gradient(90deg,
              rgba(255, 255, 255, 0.008841036414565795) 0%,
              rgba(45, 59, 89, 1) 0%);
    }

    .clientInfoHeaderContainer {
      width: 100%;
    }

    .dashboardTitle {
      width: 100%;
    }

    .dashboardTitle p {
      margin: 0;
    }

    .dashboardTitle #headerInfo {
      margin-left: 0;
      font-size: 21px;
      line-height: 24px;
      width: 100%;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .dashboardTitle #clientId {
      margin-left: 0;
      margin-top: 0;
      font-size: 14px;
      line-height: 17px;
      font-family: museo-sans;
    }

    .dashboardHeaderCardsContainer {
      padding: 0;
      width: 100%;
      flex: 0;
    }

    .dashboardHeaderCard {
      border-radius: unset;
    }

    .dashboardHeaderIconName {
      display: flex;
      flex-direction: column;
    }

    .headerImgCardsContainer {
      display: flex;
      align-items: center;
      width: 100%;
      height: 71px;
    }

    .headerImgCardsContainer > div {
      margin: 0;
      position: relative;
      width: 100%;
      height: inherit;
      border-radius: 0;
      font-size: 10px;
      line-height: 12px;
    }

    .headerImgCardsContainer > div .iconText {
      padding-top: 0 !important;
      font-size: 10px;
      line-height: 12px;
    }

    .headerImgCardsContainer > div img {
      padding: 6px;
      width: 35px;
      height: 35px;
    }

    .headerImgCardsContainer > div:not(:first-child):after {
      content: ' ';
      position: absolute;
      top: 18px;
      left: -0.5px;
      height: 36px;
      z-index: 10;
      border-left: 1px solid rgb(151, 151, 151);
      opacity: 0.5;
    }

    .headerImgCardsContainer .dashboardHeaderIconCard {
      margin: 0;
      width: 100%;
      height: inherit;

      &.submitToCla {
        border: 0 !important;
      }
    }

    .summaryPDFCard {
      .dashboardHeaderIconName {
        p {
          margin: 0;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .App {
    .imgContainer {
      padding: 50px 53px;
      height: auto;
    }

    .dashboardTitle #headerInfo {
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 4px;
    }

    .dashboardTitle #clientId {
      font-size: 16px;
      line-height: 19px;
    }

    .dashboardHeaderIconName {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 0;
      gap: 12px;
    }

    .dashboardHeaderIconName.iconImg {
      padding-top: 0 !important;
    }

    .dashboardHeaderIconName * {
      margin: 0;
    }

    .dashboardHeaderIconName > div img {
      padding: 6px;
      width: 42px;
      height: 42px;
    }

    .headerImgCardsContainer > div .iconText {
      padding-top: 0 !important;
      font-size: 18px;
      line-height: 22px;
    }

    .summaryPDFCard {
      .dashboardHeaderIconName {
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .App {
    .imgContainer {
      padding: 28px 23px;
    }
    
    .dashboardTitle {

      #headerInfo {
        font-family: Georgia;
        font-weight: 700;
        font-size: 18px;
        line-height: 20.45px;
        text-align: left;
        overflow: unset;
        text-wrap: balance;
        padding-bottom: 4px;
      }
      
      #clientId{
        font-family: museo-sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
      }
    }

  }
}