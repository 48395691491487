.key-statistic-tile {
  flex-grow: 0;
  flex-basis: 100%;
  border-radius: 3px;
  border: solid 1px rgba(151, 151, 151, 0.25);
  padding: 13px;
  background-color: #fff;
  display: block;
}

.key-statistic-tile:not(:last-child) {
  margin-right: 10px;
}

.key-statistic-tile-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.key-statistic-tile-count {
  flex-grow: 1;
  font-family: museo-sans;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e334e;
  display: inline-block;
}

.key-statistic-tile-label {
  flex-grow: 1;
  font-family: museo-sans;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
}

.key-statistic-tile-progress-bar {
  display: flex;
  flex-direction: row;
}

.key-statistic-tile-toggled {
  border: 3px solid #7dd2d3;
}