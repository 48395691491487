.tax-return-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 128px;
  width: 100%;
  background-color: #E2E4EE;
  padding-left: 100px;
  padding-right: 100px;
  text-align: left;
  position: relative;
  overflow: hidden;
  max-height: 118px;
  opacity: 1;

  .sign-tax-return-btn {
    height: 35px;
    width: 157px;
  }

  .tax-return-icon-text {
    display: flex;
    align-items: center;

    .tax-return-base-text {
      font-family: museo-sans;
      color: #2D3C59;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding-left: 22px;

      .header-text {
        max-width: 910px;
        font-size: 17px;
        font-weight: 600;
        line-height: 20.4px;
      }
    }
  }
}