.downloadNewFiles {

}

.downloadNewFiles.above {
    position: absolute;
    margin-top: -50px;
}

.newFilesLabel, .downloadLabel {
    padding: 3px 5px;
    background: #24787A;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: smaller;
}

.downloadLabel {
    font-size: small;
}

.download {
    font-size: inherit;
    height: inherit;
    width: inherit;
    color: #fff;
    font-size: medium;
}

.download, span {
    margin: 0 5px;
}

.downloadButton {
    cursor: pointer;
}