/** Dashboard SubMenu Dropdown Menu buttons **/
.dashboardSubMenu {
    font-family: museo-sans;
    padding: 0 0 25px 49px;
    display: flex;
  }
  
  #showMenu img {
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }
  
  .dashboardSubMenuDropdown button {
    font-family: museo-sans;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #595959;
    border-radius: 0;
    border-bottom: solid 1px #d8d8d8;
    margin: 0.5rem 1rem 0 0;
  }
  
  .dashboardSubMenuDropdownIcon {
    margin-right: -20px;
    color: #2d3c59;
    width: 15px;
  }
  
  .dashboardSubMenuDropdownLabel {
    margin-left: -15px;
  }

  .dashboardSubMenuViewLessLabel {
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 45px;
  }

  .dashboardSubMenuJumpTo{
    /* margin-left:60px;
    margin-right:8px;
    align-items: right; */
    margin-top: 8px;
    margin-bottom: 8px;
    grid-column: 2;
  }
  
  .dashboardSubMenuDropdownSortTextLabel, .dashboardSubMenuDropdownShowTextLabel {
    margin-right: 2px;
    font-family: museo-sans;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #595959;
  }
  
  .dashboardSubMenuDropdownSortTextValue, .dashboardSubMenuDropdownShowTextValue {
    min-width: 66px;
    text-align: left;
    font-weight: 500;
    font-size: 10px;
  }
  
  /** Dashboard SubMenu Dropdown Menu items **/
  .dashboardSubMenuDropdown, .rmd-menu {
    border-radius: 4px;
  }
  
  .dashboardSubMenuDropdown .rmd-menu .rmd-list {
    margin: 0;
    width: 145px;
    padding: 10px 0 10px 0;
  }
  
  .dashboardSubMenuDropdown .rmd-menu .rmd-list .rmd-list-item {
    color: #2d3c59;
    min-height: 0;
    font-size: 11px;
  }
  
  .dashboardSubMenuDropdown .rmd-menu .rmd-list .rmd-list-item .rmd-list-item__text {
    font-family: museo-sans;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.05px;
    padding: .5px 0px;
  }
  
  .dashboardSubMenuDropdown .rmd-menu .rmd-list .rmd-list-item:hover {
    background-color: #76d2d3;
  }