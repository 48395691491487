.tax-return-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #E2E4EE;
  padding: 44px 52px;
  gap: 39px;
  text-align: left;
  position: relative;
  overflow: hidden;
  max-height: none;
  opacity: 1;

  img {
    width: 137px;
    height: 137px;
  }

  .sign-tax-return-btn {
    height: 35px;
    width: 157px;
  }

  .tax-return-icon-text {
    display: flex;
    align-items: center;

    .tax-return-base-text {
      font-family: museo-sans;
      color: #2D3C59;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding-left: 22px;

      .header-text {
        max-width: 910px;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 18px;
      }

      .secondary-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 23px;
      }

      .sign-tax-return-btn {
        text-transform: capitalize;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .tax-return-banner {
    justify-content: center;
    padding: 30px 12px;

    .tax-return-icon-text {
      flex-direction: column;
      gap: 17px;
      text-align: center;
      max-width: 300px;

      img {
        width: 79px;
        height: 79px;
      }

      .tax-return-base-text {
        padding: 0;

        .header-text {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 9px;
        }

        .secondary-text {
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .tax-return-banner {
    .tax-return-icon-text {
      .tax-return-base-text {
        .header-text {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 6px;
        }
      }
    }
  }
}