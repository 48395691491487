.entry-modal-content-mobile{
  width: 90% !important;
}

.entry-dialog-mobile-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #F6F6F6;
}

.simple-entity-dialog{
  overflow: hidden !important;
}

.entry-dialog-container {
  height: 740px !important;
  width: 903px !important;
  border-radius: 5px !important;
}

.acceptContinueButton {
  padding: 8px 32px !important;
}

.declineButton {
  padding: 4px 22px !important;
}

.entry-modal-li-h4 {
  font-size: 14px;
}

.entry-modal-li-p {
  font-size: 14px;
}

.rmd-overlay {
  margin: 0;
}

.entry-experience-title {
  p {
    font-size: 25px;
    font-weight: 600;
    color:#303E59;
    margin: 0px;
    line-height: 25px;
    margin-top: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .entry-experience-title {
    font-size: 16px !important;
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-weight: 400 !important;
    line-height: 18.18px !important;
    color: #303E59 !important;
    letter-spacing: -0.28px !important;
    margin-top: 5px !important;

    p {
      font-size: 16px;
    }
  }

  .rmd-dialog__header {
    flex: auto;
    padding: 15px;
  }

  .entryHeaderSilIcon {
    margin-left: 20px !important;
  }

  .entry-please-tell-us-about {
    font-size: 14px !important;
    color: #2E334E !important;
  }

  .entry-modal-li-h4 {
    font-size: 12px !important;
  }

  .entry-modal-li-p {
    font-size: 12px !important;
    color: #4B4B4B !important;
  }
}

@media only screen and (max-width: 1024px) {
  .entry-modal-content {
    width: 92% !important;
    height: 65% !important;
    border-radius: 3px !important;
    margin: 10px auto !important;
  }  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .entry-experience-title {
    p {
      font-size: 24px;
    }
  }
}