.my-dashboards {
  padding: 22px;
}

.my-dashboards-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-section-header {
  text-align: left;
  display: flex;
}

.dashboard-section-header h1 {
  font-family: museo-sans;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #072c43;
}

.dashboard-section-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  position: relative; 
}

.my-dashboards-header-title {
  font-family: Georgia;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e334e;
}

.my-dashboards-header-expand {
  text-align: right;
  flex-grow: 1;
}

.my-dashboards-filter-label {
  font-family: museo-sans;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #595959;
}

.my-dashboards-expand-btn {
  font-family: museo-sans;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #2e334e;
  padding-right: 0
}

.my-dashboards-expand-icon {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
}

.chevron-button {
  margin: 0;
  padding: 0;
}

.chevron-icon {
  width: 30px;
  height: 30px;
}