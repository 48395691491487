.breadcrumb-item {
    text-decoration: none;
    font-weight: 400;
    color: #424548;
    background-color: inherit;
}

.breadcrumb-item-link {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    padding: 8px 0 0;
    margin: 0 8px 6px;
    color: #535C8D;
    border-bottom: 2px solid #535C8D;;
}

.breadcrumb-item-link-current {
    font-weight: 500;
    color: #424548;
}

.breadcrumb-item a {
    background-color: inherit;
}

.breadcrumb-item-link a:hover {
    color: #1da2b3;
}