.appFooterMobile {
  display: flex;
  max-height: 122px;
  background-color: #2D3C59;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 37px 21px;
  bottom: 0;
}

.appFooterMobileLogo {
  width: 50px;
  height: 50px;
}

.appFooterMobileTextSection {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
}

.appFooterMobileText {
  text-align: left;
  color: #ffffff;
}

.appFooterMobileVersion {
  margin: 0;
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .appFooterMobileTextSection {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
  }
}