.errorCodeDataTableBody {
    div{
        display: flex;
        justify-content: space-between;
        p{
            margin:0;
            margin-bottom: 9px;
        }
    }
}
.pagebreak{
    .rmd-form-message__message {
        background-color: white;
        position: relative;
        top: -8px;
    }
}
