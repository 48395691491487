.form-footer-nav-mobile {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 20px 0;
    border-top: 1px solid #D8D8D8;
    justify-items: center;
    font-family: museo-sans;

    .return-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-decoration: underline;
        color: #2D3C59;
    }

    .icon-circle {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }

    .min-width {
        width: auto;
        min-width: auto;
    }

    .prev {
        justify-self: flex-start;
        grid-column: span 1;
    }

    .return-text-link {
        grid-column: 4 span;
    }

    .next {
        justify-self: flex-end;
        grid-column: 1 span;
    }
}

@media only screen and (min-width: 768px) {
    .form-footer-nav-mobile {
        .font-style {
            font-size: 12px;
            font-weight: 700;
            font-family: inherit;
        }

        .prev-link {
            .font-style {
                width: 85px;
                text-align: left;
            }
        }

        .next-link {
            .font-style {
                width: 74px;
                text-align: right;
            }
        }

        .prev {
            justify-self: flex-start;
            grid-column: 2 span;
        }

        .return-text-link {
            grid-column: 2 span;
        }

        .next {
            justify-self: flex-end;
            grid-column: 2 span;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .form-footer-nav-mobile {
        .prev-link {
            .font-style {
                width: 135px;
                text-align: left;
            }
        }

        .next-link {
            .font-style {
                width: 110px;
                text-align: right;
            }
        }
    }
}