.cardFormDetails {
  text-align: left;
  height: 10px;
  font-size: 11px;
  font-family: museo-sans;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  color: #757575;
}

.cardFormDetails div:nth-child(1) {
  flex: 2;
  align-items: flex-start;
  margin-left: -2%;
}

.cardFormDetails div:nth-child(2) {
  margin-right: -4%;
}

.cardFormDetails .goToForm {
  width: 15px;
  height: 10px;
}

.cardContainer {
  width: 220px;
  min-width: 180px;
  /* height: 150px; */
  /* margin-bottom: 10px; */
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-top: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.viewCardDetails {
  height: 210px;
}

.cardSpacing:hover {
  border-bottom: 5px solid #7dd2d3;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.cardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.cardTitle {
  flex-grow: 3;
  flex-basis: 0;
  align-self: center;
  display: flex;
}

.cardIconContainer {
  flex: 0.5;
  flex-shrink: 0;
  align-self: center;
}

.overHangImg {
  position: relative;
  height: 35px;
  width: 35px;
}

.cardTitleContainer {
  flex-grow: 1;
  flex-basis: 0;
  text-align: left;
}

.cardTitleContainer p {
  font-family: museo-sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2d3c59;
  margin: 10px 10px 5px 0;
}

.cardTitleContainer .cardTitleTextWidth {
  width: 110px;
}

.cardStatusContainer {
  height: 35px;
}

.cardProgressLabelContainer {
  display: flex;
  margin: 0 5.3% 0;
  color: #757575;
  font-family: museo-sans, sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.cardProgressLabelContainer .last{
  text-align: right;
}

.cardProgressLabelContainer div {
  flex: 1;
  margin-bottom: -25px;
  font-size: 12px;
}

.cardProgressLabelContainer .status {
  flex: 1;
  font-size: 12px;
  margin-top: -40px;
}

.cardProgressLabelContainer div:nth-child(1) {
  text-align: left;
}

.cardProgressLabelContainer div:nth-child(2) {
  text-align: right;
}

.cardLinearProgress {
  margin-bottom: 1rem;
  width: 90%;
  justify-content: flex-start;
  background-color: #E0E0E0;
}

.cardViewDetailsBtn {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin-top: 10px;
  margin-left: 5px;
}

.cardViewNaBtn {
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.cardViewDetailsBtn div:nth-child(1) {
  flex: 1;
  font-size: 12px;
  text-align: left;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
}

.cardMoreBtnContainer {
  width: 48px;
height: 12px;
flex-grow: 0;
margin: 0 43px 0 0;
}

.cardButton{
  width: 126px;
height: 12px;
flex-grow: 0;
margin: 0 10px 0 0px;
font-family: museo-sans;
font-size: 10px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: right;
text-overflow: unset;
color: #757575;
}

.cardExpandButtonContainer{
  width: 8px;
height: 1px;
flex-grow: 0;
margin: 0 0 0 1px;
border-radius: 3px;
border: solid 1px #757575;
background-color: #c4c4c4;
}

.cardDetails{
  width: 33px;
height: 12px;
flex-grow: 0;
margin-left: 0px;
font-family: museo-sans;
font-size: 10px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #757575;
}

.viewLessDetails {
  width: 80px;
  height: 9px;
  flex-grow: 0;
  font-family: museo-sans;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e334e;
}

.viewLess{
  margin-left: 0px;
}

.cardSubtitle{
  font-family: museo-sans;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2d3c59;
}

.cardExpandedView{
font-family: museo-sans;
font-size: 10px;
font-weight: 500;
font-style: normal;
margin: 0px !important;
color: #757575;
text-align: left;
}

.cardGridView{
margin: 0px !important;
display: grid;
grid-template-columns: 190px repeat(auto-fill, 20px) 5px;
margin: 8px 10px 8px 10px;
}

.cardExpandedView2{
font-family: museo-sans;
font-size: 10px;
font-weight: 500;
font-style: normal;
margin-top: 4px;
margin-bottom: 4px;
color: #757575;
text-align: left;
line-height: normal;
letter-spacing: normal;
grid-column: 1;
}

.taxReturnCard {
cursor: pointer;
}