@media screen and (max-width: 1439px) {
  .App{

      .dashboardCategoriesHeader {
          padding: 0;
      }

      .cardIconContainer{
          margin-right: 10px;
          flex: 0;
      }

      .dashboardCardHeaderTitle {
          padding: 0;
      }

      .dashHeaderTitle{
          text-align: left;
          text-wrap: wrap;
      }

      .viewAllBtn {
          padding: 0 10px 0 10px;
      }
      .dashboardGridListCategory{
          padding: 0;
      }
      .cardGridCell {
          margin: 0;
          width: -webkit-fill-available;
      }

      #dashboardCategoryDivider {
          padding: 0;
          margin-bottom: 20px;
      }
  }
}

@media screen and (min-width: 320px) {
  .App{

      .dashboardHeader {
          padding: 0 15px 15px;
      }

      .dashHeaderTitle{
          font-size: 18px;
          font-weight: 400;
          line-height: 20.45px;
      }

      .dashboardGridListCategory.rmd-grid{
          grid-row-gap: 15px;
      }
  }

}
@media screen and (min-width: 768px) {
  .App{

      .dashboardHeader {
          padding: 0 20px 20px;
      }
      .dashboardGridListCategory.rmd-grid{
          grid-column-gap: 10px;
          grid-row-gap: 10px;
      }
  }

}

@media screen and (min-width: 1024px) {
  .App{

      .dashboardHeader {
          padding: 0 30px 30px;
      }
  }

}

@media screen and (min-width: 1025px) and (max-width: 1439px){
  .App{
      .dashboardGridListCategory.rmd-grid{
          --rmd-grid-cols:9;
      }
  }
}
@media screen and (min-width: 1440px){
  .App{
      .dashboardGridListCategory.rmd-grid{
          --rmd-grid-cols:10;
          gap: 20px;
      }

      .dashHeaderTitle{
          font-size: 17px;
          font-weight: normal;
          line-height: normal;
      }
  }
}