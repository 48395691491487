.footer-navbar {
    box-sizing: border-box;
    padding: 20px;
    margin: 0 auto;
    background: #ffffff;
    border: solid 1px #d8d8d8;
    display: flex;
    justify-content: space-between;
    width: 94%;
}

.font-style {
    font-family: Georgia;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e334e !important;
}

.icon-circle {
    background-color: #d8d8d8;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.item-flex {
    display: flex;
    gap: 5px;
    align-items: center;
}

.disable-button {
    pointer-events: none;
}

.text-underline {
    text-decoration: underline;
}

.min-width {
    min-width: 15%;
}