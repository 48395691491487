@media (max-width: 1024px) {
    .App {
        .rmd-grid__cell--8 {
            grid-column-end: span 8;
        }

        .sectionHeaderContainer {
            padding: 0 8px 0 18px;

            @media (min-width: 768px) {
                padding: 0 8px 0 20px;
            }

            @media (min-width: 1024px) {
                padding: 0 8px 0 30px;
            }
        }

        .groupDetailsContainer {
            padding-left: 30px !important;
            padding-right: 30px !important;

            @media (min-width: 320px) and (max-width: 767px) {
                padding-left: 18px !important;
                padding-right: 18px !important;
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                padding: 20px 20px 0 20px !important;
            }
        }

        .formLineRowContainer {
            @media (min-width: 1024px) {
                padding: 20px 30px 0 30px !important;
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                padding: 20px 20px 0 20px !important;
            }

            @media (min-width: 768px) {
                .rmd-grid__cell--8 + .rmd-grid__cell--4 {
                    margin-top: 15px;
                }
            }

            .labelText {
                &.rmd-grid__cell--8 {
                    padding-bottom: 5px;
                }

                @media (min-width: 768px) and (max-width: 1023px) {
                    &.rmd-grid__cell--8 {
                        padding-bottom: 0;
                    }
                }

                @media (max-width: 767px) {
                    &.rmd-grid__cell--8 {
                        padding-bottom: 16px;
                    }

                }

                & > div {
                    font-weight: 400;
                }
            }

            #lbl-didYouLabel {
                font-weight: 600;
                margin-bottom: -12px;
            }

            .uploadFieldMobile {
                margin-top: 4px;
                margin-bottom: 4px;
                padding: 20px 18px;

                @media (min-width: 320px) {
                    margin-left: -18px;
                    margin-right: -18px;
                }

                @media (min-width: 768px) {
                    padding: 20px;
                    margin-left: -20px;
                    margin-right: -20px;

                    .rmd-grid__cell--4 {
                        margin-top: 0;
                    }
                }
            }
        }

        .labelLeftSingleLine {
            margin-top: 0;
            margin-left: 0;
            font-weight: 600;
            height: auto;
        }

        .tellUsSection {
            .formCheckboxesHeaderContainer {
                margin-top: 0;
                padding-left: 18px;

                @media (min-width: 768px) {
                    padding-left: 20px;
                }

                @media (min-width: 1024px) {
                    padding-left: 30px;
                }

                .multiSelectText1 {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 700;
                }
            }
        }

        .cryptocurrencySection {
            padding-top: 4px;

            .formLineRowContainer {
                @media (min-width: 768px) {
                    padding: 0 20px 0 20px !important;
                }

                @media (min-width: 1024px) {
                    padding: 0 30px 0 30px !important;
                }
            }

            #lbl-aboutYourYearCryptoNotReportedLabel {
                margin-top: 16px;

                @media (min-width: 768px) and (max-width: 1023px) {
                    margin-bottom: 16px;
                }
            }

            .labelTitleTextAreaIndented {
                padding: 16px 0;
                margin: 0;
            }

            .fieldTextAreaIndented {
                margin: 0 0 16px;
            }

            .labelLeftSingleLine {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                height: auto;

                @media (min-width: 768px) {
                    margin-top: 0 !important;
                }
            }

            .labelText:has(#lbl-aboutYourYearCryptoEarn, #lbl-aboutYourYearCryptoBuySell, #lbl-aboutYourYearCryptoHold) {
                margin-top: 0 !important;
                padding-left: 11px;
                padding-bottom: 0;
            }

            .labelText:has(#lbl-aboutYourYearCryptoOwn) {
                padding-left: 11px;
            }

            #hover-aboutYourYearCryptoQuestion-1 {
                margin-top: 16px;
            }
        }

        .foreignMattersSection {
            .groupDetails,
            .groupDetails2 {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                span {
                    margin: 0;
                }
            }

            .groupDetails2 {
                border-bottom: 2px inset rgba(0, 0, 0, 0.12);
                padding-bottom: 16px;
            }

            .groupDetailsContainer:has(.groupDetails2) {
                margin-bottom: 0;
            }

            .groupDetailsContainer {
                @media (min-width: 768px) {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                &:has(.labelLeftSingleLine) {
                    margin-bottom: 0;

                    @media (min-width: 768px) {
                        margin-bottom: -10px;
                    }
                }
            }
        }

        .financialServicesSection {
            a {
                word-break: break-word;
            }

            @media (min-width: 768px) {
                .formLineRowContainer {
                    &:not(:last-child) {
                        padding-top: 0 !important;
                    }

                    &:first-child {
                        .labelLeftSingleLine {
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        .additionalInformationSection {
            .groupDetailsContainer {
                margin-top: 0;
                margin-bottom: 20px;

                @media (min-width: 768px) {
                    margin-bottom: 0;
                }

                .labelLeftLight {
                    margin-left: 0;
                }
            }

            .rmd-textarea-container {
                height: 380px !important;
            }

            .rmd-textarea-container__inner {
                height: 100% !important;
                min-height: 380px;
                padding-top: 14px;
            }
        }
    }
}