
.esignatureStatusHeaderContainer {
    min-height: 45px;
    height: 51px !important;
    padding: 0px !important;
    border-top: solid 1px rgba(151, 151, 151, 0.25);
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
    background-color: #f7f7f7;
    align-items: center;
}

.esignatureStatusHeaderContainer > div:first-child {
    padding-left: 45px !important;
}

.esignatureStatusHeaderContainer > div:last-child {
    padding-right: 45px !important;
}

.esignatureStatusGridContainer {
    min-height: 45px;
    align-items: center;
    align-content: center;
    margin: 20px 0px !important;
    padding: 0px !important;

    .last-cell-div {
        padding-right: 45px !important;
    }
}

.esignatureStatusGridContainer > div:first-child {
    padding-left: 45px !important;
}

.decline-reason {
    min-height: 45px !important;
    border: #97979780 solid 1px;
    color: #595959;
    padding: 14px 15px !important;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 12px !important;
    word-wrap: break-word !important;
}

.gridCell-padding {
    padding: 0px 45px !important;
}

.dialog-header {
    padding-top: 0px !important;
    padding-left: 46px !important;
    padding-right: 46px !important;
    padding-bottom: 0px !important;
}
.dialog-body {
    padding-top: 12px !important;
    padding-left: 0px !important;
    padding-right: 0 !important;
    padding-bottom: 0px !important;
}

.esignature-status-dialog {
    width: 683px !important;
}

.grid-divider {
    margin-top: 10px !important;
}

.loading-div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.dialog-footer {
    padding-bottom: 40px !important;
}
.dialog-footer > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.esignature-dialog-header {
    font-size: 21px !important;
    font-weight: bold !important;
    color: #2E334E !important;
    font-family: 'Georgia';
    padding-top: 19px !important;
}

.esignature-dialog-sub-header {
    font-size: 14px !important;
    font-weight: normal !important;
    margin-top: 10px !important;
    color: #4B4B4B !important;
}
.email-dialog-font {
    font-size: 14px !important;
}

.esignature-status-header {
    font-size: 14px !important;
    font-weight: 900 !important;
    color: #2E334E !important;
}

.esignature-status-cell {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #2E334E !important;
    word-wrap: break-word !important;
    padding: 0px !important;
}

.esignature-status-grid {
    width: 100% !important;
    margin-top: 10px !important;
}

.closeButtonActive {
    width: 98px;
    border-radius: 3.5px;
    border: solid 2.5px #2d3b59;
    background-color: #2E334E;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin: 0 5px 0 5px;
}

.taxReturnStatusNotSent {
    color: gray;
}

.taxReturnStatusSent {
    font-weight: bold;
    font-style: italic;
}

.taxReturnStatusSigned {
    color: green;
    font-weight: bold;
}

.taxReturnStatusDeclined {
    color: red;
    font-weight: bold;
}
