/* Document Manager */
.docManagerGridContainer {
	padding-bottom: 20px;
	position: relative;
}

#nested-dropdown-menus button::after {
	display: none;
}

.moveBtn {
	font-size: 11px;
	font-weight: bold;
	padding: 0 5px !important;
}

.moveFileText {
	font-weight: 1000;
	border-bottom: 1px gray solid;
}

.uploadsActionBtnsContainer {
	text-align: left !important;
}

.uploadsActionBtnsContainer button[data-testid="uploadsActionBtns-download"] {
	width: 60px;
}

.uploadsActionBtnsContainer button[data-testid="uploadsActionBtns-delete"] {
	width: 40px;
}

.uploadsActionBtnsContainer button[data-testid="uploadsActionBtns-preview"] {
	width: 50px;
}

.docManagerCell {
	display: flex;
	align-items: center;
}

.renameFile {
	width: auto;
}